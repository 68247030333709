import React from 'react';
import { useTranslation } from 'react-i18next';
import { Txt, StandAloneLink, Icon } from '@vst/beam';
import { Error } from '@vst/beam-icons/icons';
import { useDispatch } from 'react-redux';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import { structuredEvent } from '@mfe/to-be-migrated/redux/analytics';
import { useStreamOnAnalyticsContext } from '../utils';
import styles from '../styles.module.scss';

const ErrorReplaceHub = (): JSX.Element => {
  const { t } = useTranslation('StreamOn');
  const dispatch = useDispatch();
  const { displayContext, streamOnStatus } = useStreamOnAnalyticsContext();

  const replaceHubLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const url = t('troubleshootConnectivityLink', { ns: 'Global' });
    dispatch(
      structuredEvent({
        category: 'Stream',
        action: 'learn_more_selected',
        params: {
          property: streamOnStatus ?? '',
          context: [displayContext],
        },
      })
    );
    dispatch(openInNewTab(url));
  };

  return (
    <div className={styles['streamNotif'] + ` ${styles['error']}`}>
      <Icon icon={Error} mr="4px" color="alert_400" style={{ width: '16px' }} />
      <div className={styles['infoText']}>
        <Txt pb="16px" variant="smallRegular" component="p">
          {t('Body.replaceHub1')}
        </Txt>
        <Txt variant="smallRegular">
          {t('Body.replaceHub2')}
          <Txt component="span" variant="smallBold">
            {t('Body.replaceHub3')}{' '}
          </Txt>
          <StandAloneLink
            id="replaceHubLink"
            variant="secondary"
            linkSize="medium"
            href=""
            onClick={replaceHubLink}
            style={{ textDecoration: 'underline', fontSize: '14px' }}
          >
            {t('Body.learnMore')}
          </StandAloneLink>
        </Txt>

        <Txt pt="16px" variant="smallRegular" component="p">
          {t('Body.callUsAt')}
        </Txt>
      </div>
    </div>
  );
};

export default ErrorReplaceHub;
