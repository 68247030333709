import React from 'react';
import { EmptyState, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';

export const DisconnectedState = (): JSX.Element => {
  const { t } = useTranslation('Usage');

  return (
    <Surface my={'72px'}>
      <EmptyState description={t('monthlyUsageChart.disconnectedMsg')} />
    </Surface>
  );
};
