import {
  Accordion,
  AccordionGroup,
  Button,
  StandAloneLink,
  Txt,
} from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AccountType, Locale } from '@mfe/shared/schema-types';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import { HelpLink } from '@mfe/legacy/mv/utils/Navigation';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import useNavigate from '@mfe/services/navigation';

import styles from './styles.module.scss';

type Link = {
  label: string;
  url: string;
};

type Question = {
  header: string;
  description: string[];
  links?: Link[];
};

export const UsageCommonQuestions = () => {
  const dispatch = useDispatch();
  const { goTo } = useNavigate();
  const { t } = useTranslation(['CommonQuestionsUpdated', 'Global']);

  const {
    loading,
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const translationsLocale =
    userLocale === Locale.PtBr ? Locale.EnUs : userLocale;

  // For US, displayed questions differ between residential and SMB,
  // 3 of them are common for both, residential has an additional 2
  const sharedQuestions: Question[] = t(
    `CommonQuestionsUpdated:PlanUsage.${userLocale}.common`,
    { returnObjects: true }
  );

  //If this is going to be an ongoing thing then we need to add a sort order to the question translation files and sort them here
  const firstQuestionItem = sharedQuestions.shift() ?? sharedQuestions[0];

  const residetialOnlyQuestions: Question[] = t(
    `CommonQuestionsUpdated:PlanUsage.${translationsLocale}.residentialOnly`,
    { returnObjects: true }
  );

  const questions: Question[] =
    accountType === AccountType.Smb
      ? [firstQuestionItem, ...sharedQuestions]
      : [firstQuestionItem, ...residetialOnlyQuestions, ...sharedQuestions];

  const navigateToFaq = (e: React.MouseEvent<HTMLElement>) => {
    const url = t('Global:SMBSupportLink');

    accountType === AccountType.Smb
      ? openLinkInNewTab(e, url)
      : goTo(HelpLink.Main, { section: 'Usage' });
  };

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  return (
    <div>
      <Txt variant="heading5" mb="16px">
        {t('CommonQuestionsUpdated:title')}
      </Txt>
      {loading ? (
        <Loading />
      ) : (
        <AccordionGroup singleExpanded={true} size="small">
          {questions.map(({ header, description, links }, questionKey) => (
            <Accordion
              title={header}
              key={questionKey}
              className={styles['accordionHeader']}
            >
              <div className={styles['accordionDescription']}>
                {description?.map((line: string, lineKey) => (
                  <Txt variant="bodySmallRegular" key={lineKey}>
                    {line}
                  </Txt>
                ))}
                {links &&
                  links?.map(({ label, url }, linkKey) => (
                    <StandAloneLink
                      href={url}
                      key={linkKey}
                      onClick={(e) => openLinkInNewTab(e, url)}
                    >
                      {label}
                    </StandAloneLink>
                  ))}
              </div>
            </Accordion>
          ))}
        </AccordionGroup>
      )}
      {userLocale === Locale.EnUs && (
        <Button
          fluid
          variant="secondary"
          mt="16px"
          onClick={(e) => navigateToFaq(e)}
        >
          {t('CommonQuestionsUpdated:viewFaqs')}
        </Button>
      )}
    </div>
  );
};

const Loading = () => {
  return (
    <div className={styles['loadingContainer']}>
      <div className={styles['loadingCell']} />
      <div className={styles['loadingCell']} />
      <div className={styles['loadingCell']} />
    </div>
  );
};
