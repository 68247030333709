import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Icon, Txt } from '@vst/beam';
import {
  LocalShipping,
  ShoppingBag,
  CheckCircleOutline,
  PowerOff,
} from '@vst/beam-icons/icons';

import { StreamOnStatus } from '@mfe/shared/schema-types';

import styles from '../styles.module.scss';

const StatusIcon = ({
  state,
}: {
  state: StreamOnStatus;
}): JSX.Element | null => {
  switch (state) {
    case StreamOnStatus.Pending:
      return <Icon icon={ShoppingBag} color="info_secondary_500" size={16} />;
    case StreamOnStatus.Shipping:
    case StreamOnStatus.Delivered:
      return <Icon icon={LocalShipping} color="info_primary_500" size={16} />;
    case StreamOnStatus.Active:
      return <Icon icon={CheckCircleOutline} color="success_500" size={16} />;
    case StreamOnStatus.Error:
    case StreamOnStatus.ErrorReplaceHub:
    case StreamOnStatus.Unplugged:
      return <Icon icon={PowerOff} color="alert_400" size={16} />;
    case StreamOnStatus.RequestErrored:
    default:
      return null;
  }
};

const getTextColor = (state: StreamOnStatus) => {
  switch (state) {
    case StreamOnStatus.Unplugged:
      return 'warning';
    case StreamOnStatus.Error:
    case StreamOnStatus.ErrorReplaceHub:
      return 'error';
    case StreamOnStatus.Active:
      return 'success';
    default:
      return 'info';
  }
};

const getTitleStatusClass = (state: StreamOnStatus) => {
  switch (state) {
    case StreamOnStatus.Unplugged:
    case StreamOnStatus.Error:
    case StreamOnStatus.ErrorReplaceHub:
      return styles['streamOnTitleStatus'] + ` ${styles['error']}`;
    case StreamOnStatus.Active:
      return styles['streamOnTitleStatus'] + ` ${styles['active']}`;
    case StreamOnStatus.Pending:
      return styles['streamOnTitleStatus'] + ` ${styles['pending']}`;
    default:
      return styles['streamOnTitleStatus'] + ` ${styles['info']}`;
  }
};

const StreamCardHeader = ({
  state,
}: {
  state: StreamOnStatus;
}): JSX.Element => {
  const { t } = useTranslation('StreamOn');

  return (
    <div className={styles['streamOnTitle']} id="StreamOnTitle">
      <div className={styles['streamOnTitleLeft']}>
        <Txt variant="paragraphBold" mr="8px">
          {t('cardTitle')}
        </Txt>
        <Badge
          showIcon={false}
          label="Beta"
          state="infoSecondary"
          emphasis="light"
          size="small"
        />
      </div>
      {state !== StreamOnStatus.RequestErrored && (
        <div className={getTitleStatusClass(state)} id="StreamOnTitleStatus">
          <StatusIcon state={state} />
          <Txt
            id="status"
            style={{ whiteSpace: 'nowrap' }}
            color={getTextColor(state)}
            variant="labelTiny"
            ml="4px"
          >
            {t('Title.title', { context: state })}
          </Txt>
        </div>
      )}
    </div>
  );
};

export default StreamCardHeader;
