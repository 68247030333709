import { createContext, useContext } from 'react';

import {
  DisplayZone,
  getDisplayPlatform,
} from '@mfe/to-be-migrated/redux/analytics';
import { StreamOnStatus, TokenType } from '@mfe/shared/schema-types';

export const StreamOnAnalyticsContext = createContext<{
  displayContext: any;
  streamOnStatus: StreamOnStatus | null;
}>({
  displayContext: {},
  streamOnStatus: null,
});

export const useStreamOnAnalyticsContext = () =>
  useContext(StreamOnAnalyticsContext);

export const getDisplayContext = (
  tokenType: TokenType | undefined,
  page?: string
) => ({
  schema: 'display_context',
  data: {
    display_zone: page ?? DisplayZone.OVERVIEW_PAGE,
    display_platform: getDisplayPlatform(tokenType),
    display_location: 'body',
  },
});
