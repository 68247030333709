import { useSelector } from 'react-redux';
import { subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ConventionalNativeSelect } from '@vst/beam';

import { selectCurrentUsage } from '@mfe/to-be-migrated/redux/plan';
import { parseDateStringWithoutTime } from '@mfe/shared/util';

export const DonutChartBillCycle = (): JSX.Element | null => {
  const { t } = useTranslation('UsageHistory');
  const {
    data: { startDate, endDate },
  } = useSelector(selectCurrentUsage);

  if (!startDate || !endDate) {
    return null;
  }

  const options = [];

  const billStartDate = t('startDate', {
    date: parseDateStringWithoutTime(startDate),
  });

  const billEndDate = t('endDate', {
    date: subDays(parseDateStringWithoutTime(endDate), 1),
  });

  if (billStartDate && billEndDate) {
    // \u2013 is for &ndash; HTML symbol
    options.push({ label: `${billStartDate} \u2013 ${billEndDate}`, value: 0 });
  }

  return (
    <ConventionalNativeSelect
      data-cy="donutchart-billcycle-dropdown"
      id="billCycle"
      fluid={true}
      disabled={true}
      options={options}
    />
  );
};
