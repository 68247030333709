import { useEffect, useState } from 'react';
import Styles from './DailyUsageChart.module.scss';
import { Header } from '../Header';
import { ChartLoading } from '../../components/ChartLoading';
import { BillCycleBarChart } from '../../components/BarCharts';
import { ErrorState } from '../../components/ErrorState';
import { useTrackTiming } from '@mfe/shared/util';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { BillCycleDropdown } from '../../components/BillCycleDropdown';
import LoadingStyles from '../../components/Loading/Loading.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchHistoricalUsageInfo,
  selectHistoricalUsageInfo,
} from '@mfe/to-be-migrated/redux/usageHistory';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { structuredEvent } from '@mfe/to-be-migrated/redux/analytics';
import { PreInstallState } from '../../components/PreInstallState';
import { DisconnectedState } from '../../components/DisconnectedState';
import { useStoreContext } from '../../context/store';
import { useCanPlaceOrders } from '@mfe/shared/components';

// For non-unleashed plans
export const DailyUsageChart = (): JSX.Element | null => {
  const { t } = useTranslation('UsageHistory');
  const [chartReloading, setChartReloading] = useState(false);
  const { selectedBillCycle } = useStoreContext();

  const {
    userInfo: { isPreInstall },
  } = useSelector(selectUserInfo);

  const { isDisconnected } = useCanPlaceOrders();
  const { loading: isLoading, errors: hasApiErrors } = useSelector(
    selectHistoricalUsageInfo
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHistoricalUsageInfo());
  }, []);

  useEffect(() => {
    if (isLoading === false) {
      dispatch(
        structuredEvent({
          category: 'Data Usage',
          action: hasApiErrors ? 'load_failed' : 'displayed',
          params: {
            label: '',
            property: hasApiErrors ? 'failure_reason' : '',
            value: undefined,
          },
        })
      );
    }
  }, [isLoading, hasApiErrors]);

  useEffect(() => {
    //If bill cycle changes, then unmount and reload the chart, because the chart doesn't refresh for updated scrollPositionX
    setChartReloading(true);
    setTimeout(() => {
      setChartReloading(false);
    }, 0);
  }, [selectedBillCycle]);

  useTrackTiming({ eventName: 'UsageChartLoaded', isLoading });

  if (isPreInstall) {
    return (
      <div
        className={Styles['UsageChartWrapper']}
        data-cy="usage-chart-wrapper"
      >
        <div className={Styles.TitleSection}>
          <Txt p="24px" color="regular" variant="heading5">
            {t('chartTitle')}
          </Txt>
        </div>
        <PreInstallState />
      </div>
    );
  }

  if (isDisconnected) {
    return (
      <div
        data-cy={'usage-chart-wrapper'}
        className={Styles['UsageChartWrapper']}
      >
        <div className={Styles.TitleSection}>
          <Txt p="24px" color="regular" variant="heading5">
            {t('chartTitle')}
          </Txt>
        </div>
        <DisconnectedState />
      </div>
    );
  }

  return (
    <div className={Styles['UsageChartWrapper']} data-cy="usage-chart-wrapper">
      <div className={Styles.TitleSection}>
        <Txt p="24px" color="regular" variant={'heading5'}>
          {t('chartTitle')}
        </Txt>
        {!hasApiErrors && (
          <div className={Styles.BillCycleDropdown}>
            {isLoading ? (
              <div
                className={LoadingStyles.billCycleNavigatorLoading}
                data-cy="loading-billcycle"
              />
            ) : (
              <BillCycleDropdown />
            )}
          </div>
        )}
      </div>
      {hasApiErrors ? (
        <ErrorState />
      ) : (
        <>
          <Header />
          {isLoading || chartReloading ? (
            <div style={{ height: 453, display: 'flex' }}>
              <ChartLoading />
            </div>
          ) : (
            <div className={Styles.UsageChart}>
              <BillCycleBarChart />
            </div>
          )}
        </>
      )}
    </div>
  );
};
