import { useTranslation } from 'react-i18next';
import { IllustrativeIcon, Txt } from '@vst/beam';
import { Router } from '@vst/beam-icons/illustrative-icons';

import styles from './styles.module.scss';

export const PreinstallContent = () => {
  const { t } = useTranslation('Usage');

  return (
    <div className={styles['preinstall']}>
      <IllustrativeIcon
        data-cy="preinstall-icon"
        name="Router"
        icon={Router}
        color={'teal_600'}
        width={95}
        height={80}
      />
      <Txt data-cy="preinstall-text" variant="bodyLargeRegular">
        {t('preinstall')}
      </Txt>
    </div>
  );
};
