import { Txt } from '@vst/beam';
import Styles from './Legend.module.scss';
import LoadingStyles from '../Loading/Loading.module.scss';
import React from 'react';

export interface LegendProps {
  usageBucketName: string;
  used: number;
  limit: string;
  color: string | { [key: string]: any };
  isLoadingFreeZoneTime?: boolean;
}

export const Legend = (props: LegendProps): JSX.Element | null => {
  const { usageBucketName, used, limit, color, isLoadingFreeZoneTime } = props;

  if (!usageBucketName) {
    return null;
  }
  const backgroundStyle =
    typeof color === 'object'
      ? { backgroundImage: color['background'] }
      : { backgroundColor: color };

  return (
    <div data-cy="legend" className={Styles['legend']}>
      <div style={{ display: 'flex' }}>
        <span
          style={{
            borderRadius: '50%',
            width: '10px',
            height: '10px',
            ...backgroundStyle,
            marginTop: '7px',
            marginRight: '8px',
          }}
        />
        <span className={Styles.wordSpacing}>
          <Txt variant={'labelMedium'}>{usageBucketName}</Txt>
        </span>
        {isLoadingFreeZoneTime ? (
          <span
            className={LoadingStyles.freeZoneTimeLoading}
            data-cy="loading-average-usage"
          />
        ) : (
          ''
        )}
      </div>
      <Txt variant={'subHeading2'} component={'span'} pl={'16px'}>
        {used} GB
      </Txt>
      <Txt variant={'tinyRegular'} component={'span'}>{` /  ${limit}`}</Txt>
    </div>
  );
};
