import { useTranslation } from 'react-i18next';
import { IllustrativeIcon, Txt } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import styles from './styles.module.scss';

export const DisconnectedContent = () => {
  const { t } = useTranslation('Usage');

  return (
    <div className={styles['disconnected']}>
      <IllustrativeIcon
        data-cy="disconnected-icon"
        icon={Alert}
        name="Alert"
        color={'teal_600'}
        width={95}
        height={95}
      />
      <Txt data-cy="disconnected-text" variant="bodyLargeBold">
        {t('disconnected')}
      </Txt>
    </div>
  );
};
