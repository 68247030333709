import { SectionAlert, Surface } from '@vst/beam';
import { DataUsageOutlined } from '@vst/beam-icons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const InfoAlert = (): JSX.Element => {
  const { t } = useTranslation('Usage');

  return (
    <Surface variant="primary" mx="24px" mt="40px">
      <SectionAlert
        data-cy="section-alert"
        icon={DataUsageOutlined}
        label={t('monthlyUsageChart.sectionAlertMsg')}
        variant="infoPrimary"
      ></SectionAlert>
    </Surface>
  );
};
