import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { colors } from '@vst/beam';
import { Legend } from '../Legend/Legend';
import Styles from './DonutChart.module.scss';
import { useSelector } from 'react-redux';
import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';
import accessibility from 'highcharts/modules/accessibility';
import patternFill from 'highcharts/modules/pattern-fill';

if (typeof Highcharts === 'object') {
  accessibility(Highcharts);
  patternFill(Highcharts);
}

export const DonutChart = (): JSX.Element | null => {
  const { t: globalT } = useTranslation('Global');
  const { t: usageT } = useTranslation('Usage');

  const [chartTitleText, setChartTitleText] = useState({});

  const {
    data: {
      usage: {
        buyMoreDataUsed,
        buyMoreDataCap,
        monthlyDataUsed,
        monthlyDataCap,
        standardDataUsed,
        freeZoneDataUsed,
      },
      characteristics: { freeZoneTime },
    },
    loading: { characteristics: loading },
  } = useSelector(selectPlanInfo);

  const donut_chart_data = [];
  let totalUsage = 0;

  donut_chart_data.push({
    name: usageT('monthlyUsageChart.highSpeedPlan'),
    y: monthlyDataUsed,
    limit: globalT('Global:dataFormat', { amount: monthlyDataCap }),
    color: colors.teal[600],
  });
  if (monthlyDataCap) {
    totalUsage += monthlyDataUsed;
  }

  if (buyMoreDataCap) {
    donut_chart_data.push({
      name: usageT('monthlyUsageChart.highSpeedBuyMore'),
      y: buyMoreDataUsed,
      limit: `${globalT('Global:dataFormat', {
        amount: buyMoreDataCap,
      })} ${usageT('monthlyUsageChart.purchased')}`,
      // applying the custom pattern
      color: {
        pattern: {
          path: {
            d: 'M10 0L0 10',
            strokeWidth: 3,
          },
          backgroundColor: colors.teal[700],
          patternTransform: 'rotate(10)',
          width: 15,
          height: 1,
          color: colors.teal[400],
        },
        background:
          'linear-gradient(to right bottom, rgb(0, 129, 157) 40%, rgb(24, 236, 252) 40%, rgb(24, 236, 252) 60%, rgb(0, 129, 157) 60%, rgb(0, 129, 157) 100%)',
      },
    });
    totalUsage += buyMoreDataUsed;
  }

  if (standardDataUsed) {
    donut_chart_data.push({
      name: usageT('monthlyUsageChart.standard'),
      y: standardDataUsed,
      limit: usageT('monthlyUsageChart.unlimited'),
      color: colors.gray[500],
    });
    totalUsage += standardDataUsed;
  }

  if (freeZoneDataUsed) {
    donut_chart_data.push({
      name: usageT('monthlyUsageChart.freeZone', {
        freeZoneTime: freeZoneTime ? `(${freeZoneTime})` : '',
      }),
      y: freeZoneDataUsed,
      limit: usageT('monthlyUsageChart.unlimited'),
      color: colors.pink[400],
      isLoadingFreeZoneTime: loading,
    });
    totalUsage += freeZoneDataUsed;
  }

  if (totalUsage === 0) {
    donut_chart_data.push({
      name: '',
      y: 1,
      limit: '',
      color: colors.gray[100],
    });
  }

  useEffect(() => {
    setChartTitleText({
      text:
        totalUsage > 0
          ? `<span style="font-family:Uni Neue;font-weight:800;font-size:32px">${totalUsage.toFixed(
              1
            )} GB</span><br><span style="font-family:Source Sans Pro;font-weight:400;font-size:14px">${usageT(
              'monthlyUsageChart.totalDataUsage'
            )}</span>`
          : `<span style="font-family:Source Sans Pro;font-weight:400;font-size:18px">${usageT(
              'monthlyUsageChart.zeroDataUsage'
            )}</span>`,
      verticalAlign: 'middle',
      align: 'center',
    });
  }, [totalUsage]);

  const options = {
    accessibility: {
      enabled: true,
      pointDescriptionFormatter: function (point: any) {
        return usageT('monthlyUsageChart.accessibilityDonutChart', {
          pointName: point.name,
          pointY: point.y,
        });
      },
      keyboardNavigation: {
        enabled: true,
      },
    },
    animation: false,
    animationLimit: 50,
    boostThreshold: 1,
    turboThreshold: 2,
    chart: {
      width: 308,
      height: 308,
      type: 'pie',
      animation: false,
      reflow: true,
      style: {
        fontFamily: 'Uni Neue',
        maxWidth: '100%',
        height: 'auto',
      },
    },
    credits: {
      enabled: false,
      href: null, // to override default href of highcharts.com
    },
    title: {
      ...chartTitleText,
      y: totalUsage > 0 ? 30 : 20, // vertically center align the chart title within the donut
    },

    tooltip: {
      enabled: totalUsage > 0,
      animation: false,
      distance: 10,
      headerFormat: `<span style="font-family:Source Sans Pro;font-weight:400;font-size:14px">{point.point.name}</span><table>`,
      pointFormat:
        '<tr><td style="font-family:Uni Neue;font-weight:800;font-size:18px;padding:0">{point.y:,.1f} GB</td></tr>',
      footerFormat: '</table>',
      borderColor: colors.white,
      shared: true,
      outside: true,
      borderRadius: 8,
      backgroundColor: colors.white,
      useHTML: true,
    },
    plotOptions: {
      pie: {
        size: 280,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '{point.name}: {y} %',
        },
        showInLegend: false,
      },
    },
    legend: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
    series: [
      {
        type: 'pie',
        slicedOffset: 0,
        animation: false, // stop unwanted redraw of animation
        name: usageT('monthlyUsageChart.chartName'),
        innerSize: totalUsage > 0 ? '170px' : '250px',
        data: donut_chart_data,
      },
    ],
  };

  return (
    <div data-cy="donut-chart-wrapper" className={Styles['DonutChartWrapper']}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div data-cy="legend-wrapper" className={Styles['LegendWrapper']}>
        {donut_chart_data.map((data, idx) => (
          <Legend
            key={idx}
            usageBucketName={data.name}
            color={data.color}
            used={data.y}
            limit={data.limit}
            isLoadingFreeZoneTime={data.isLoadingFreeZoneTime}
          />
        ))}
      </div>
    </div>
  );
};
