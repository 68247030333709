import React from 'react';
import { ConventionalNativeSelect, Txt } from '@vst/beam';
import { useStoreContext } from '../../context/store';
import { useTranslation } from 'react-i18next';
import { selectHistoricalUsageInfo } from '@mfe/to-be-migrated/redux/usageHistory';
import { useSelector, useDispatch } from 'react-redux';
import {
  AnalyticsAction,
  Categories,
  structuredEvent,
} from '@mfe/to-be-migrated/redux/analytics';

export const BillCycleDropdown = (): JSX.Element => {
  const { t } = useTranslation('UsageHistory');
  const { setSelectedBillCycle, setShowAlertMessage } = useStoreContext();
  const { billCycleDurations } = useSelector(selectHistoricalUsageInfo);
  const dispatch = useDispatch();

  const billCycleOptions: React.HTMLProps<HTMLOptionElement>[] =
    billCycleDurations?.map((billCycle, index) => {
      const startDate = t('startDate', {
        date: new Date(billCycle.startDate),
      });

      const endDate = t('endDate', {
        date: new Date(billCycle.endDate),
      });

      // \u2013 is for &ndash; HTML symbol
      return { label: `${startDate} \u2013 ${endDate}`, value: index };
    });

  return billCycleOptions.length === 1 ? (
    <Txt data-cy="billcycle-text" variant="labelMedium" color="subtle">
      {billCycleOptions[0].label}
    </Txt>
  ) : (
    <ConventionalNativeSelect
      data-cy="billcycle-dropdown"
      id="billCycle"
      fluid={true}
      onChange={(e) => {
        const target = e.target as HTMLInputElement;
        setSelectedBillCycle(target.value);
        setShowAlertMessage(true);
        dispatch(
          structuredEvent({
            category: Categories.DataUsage,
            action: AnalyticsAction.BUTTON_CLICKED,
            params: {
              property: 'billcycle-dropdown',
              value: parseInt(target.value, 10),
            },
          })
        );
      }}
      options={billCycleOptions}
    />
  );
};
