import { useSelector } from 'react-redux';

import {
  AEMContentProvider,
  AemFeature,
  createAemUrl,
} from '@mfe/shared/components';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

export const UsageInfoAlert = ({ aemFeature }: { aemFeature: AemFeature }) => {
  const { aemBaseUrl } = useSelector(selectConfig);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  return (
    <AEMContentProvider
      src={createAemUrl({
        baseUrl: aemBaseUrl,
        locale: userLocale,
        //pass the data color to determine the feature
        feature: aemFeature,
        accountType,
      })}
    />
  );
};
