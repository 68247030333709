import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { AccountType, Locale, Platform } from '@mfe/shared/schema-types';
import {
  Surface,
  useDialogType,
  ModalContext,
  ModalLoading,
  PageAlert,
  MobileProfileButton,
  wasAlertDismissed,
} from '@mfe/shared/components';
import { BuyMore } from '@mfe/features/buy-more';
import {
  DisplayZone,
  selectAnalytics,
  AnalyticsEventNames,
  inViewSelfDescribingEvent,
  getDisplayPlatform,
} from '@mfe/to-be-migrated/redux/analytics';
import { DataUsageLoading, UsageHistory } from '@mfe/features/usage-history';
import {
  selectUtil,
  setUsagePageStatistics,
  setUsageSummaryPageStatistics,
} from '@mfe/to-be-migrated/redux/utils';
import { selectStreamOnPromo } from '@mfe/to-be-migrated/redux/streamOnPromo';
import { selectAlerts } from '@mfe/to-be-migrated/redux/alerts';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { selectCurrentUsage } from '@mfe/to-be-migrated/redux/plan';

import { ShieldAd } from './shield-ad';
import { UsageCommonQuestions } from './common-questions';
import { ArticleSection } from './article-section';
import UsageSummary from './usage-summary';
import StreamCard from './stream-card';
import styles from '../home/usage.module.scss';
import {
  getWorkOrders,
  selectWorkOrders,
} from '@mfe/to-be-migrated/redux/workOrders';
import { getHasPendingTransition } from '@mfe/to-be-migrated/redux/changePlan';

const Usage = ({
  navigateToChangePlan,
  navigateToPage,
}: {
  navigateToChangePlan: () => void;
  navigateToPage: (link: string) => void;
}): JSX.Element | null => {
  const [dialogType, dialogTypeHandlers] = useDialogType();
  const { resetDialogType, setBuyMoreDialog } = dialogTypeHandlers;
  const { t } = useTranslation();

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const { workOrders } = useSelector(selectWorkOrders);
  const userInfo = useSelector(selectUser);
  const config = useSelector(selectConfig);
  const { platform } = config;

  const {
    pageStats: {
      pages: {
        usage: { lastUpdatedTime: lastUpdatedTimeUsage },
        usageSummary: { lastUpdatedTime: lastUpdatedTimeUsageSummary },
      },
    },
  } = useSelector(selectUtil);

  const dispatch = useDispatch();
  const {
    loading: userInfoLoading,
    user: {
      auth: { isAuthenticated },
    },
  } = userInfo;

  useEffect(() => {
    if (!lastUpdatedTimeUsageSummary && isAuthenticated)
      dispatch(setUsageSummaryPageStatistics());
  }, [dispatch, lastUpdatedTimeUsageSummary, isAuthenticated]);

  useEffect(() => {
    if (!lastUpdatedTimeUsage && isAuthenticated)
      dispatch(setUsagePageStatistics());
  }, [dispatch, lastUpdatedTimeUsage, isAuthenticated]);

  useEffect(() => {
    dispatch(getHasPendingTransition());
    if (workOrders === null) dispatch(getWorkOrders());
  }, []);

  const { hasStreamOnActive } = useSelector(selectStreamOnPromo);

  const isUS = userLocale === Locale.EnUs;
  const showShieldAd = isUS && accountType === AccountType.Residential;

  const { usageContext, display_platform } = useSelector(selectAnalytics);

  useEffect(() => {
    if (!userInfoLoading)
      dispatch(
        inViewSelfDescribingEvent({
          selector: '.common-questions',
          vendor: 'com.viasat.care',
          eventName: AnalyticsEventNames.CommonQuestionsDisplayed,
          triggerOnce: false,
          data: { display_zone: 'usage_page', display_platform },
          context: [usageContext],
        })
      );
  }, [dispatch, userInfoLoading]);

  const displayContext = React.useMemo(
    () => ({
      schema: 'display_context',
      data: {
        display_zone: 'usage_page',
        display_platform: getDisplayPlatform(userInfo.user.auth.tokenInfo.type),
        display_location: 'body',
      },
    }),
    [userInfo.user.auth.tokenInfo.type]
  );

  const mobileAppModal =
    platform !== Platform.Web && dialogType !== null ? (
      <React.Suspense
        fallback={<ModalLoading onCancel={() => resetDialogType()} />}
      >
        <Surface>
          <BuyMore />
        </Surface>
      </React.Suspense>
    ) : null;

  const { alerts: allAlerts, loading: alertsLoading } =
    useSelector(selectAlerts);

  const alerts = allAlerts.filter(
    (alert) =>
      alert.type === 'global' ||
      alert.caption === t('Alerts:AccountStatus.allDataUsedNextGen.caption') ||
      alert.caption === t('Alerts:AccountStatus.error.caption') ||
      alert.caption === t('Alerts:AccountStatus.transitionError.caption')
  );

  return (
    <ModalContext.Provider value={{ dialogType, dialogTypeHandlers }}>
      {mobileAppModal ?? (
        <>
          <MobileProfileButton />
          <div className={styles['wrapper']}>
            <div className={styles['container']}>
              {!!alerts.length && (
                <div className={styles['alertSection']}>
                  {alerts
                    .filter((alert) => !wasAlertDismissed(alert))
                    .map((alert, i) => (
                      <PageAlert
                        key={i}
                        alert={alert}
                        loading={alertsLoading}
                        setBuyMoreDialog={setBuyMoreDialog}
                        analyticsContexts={{ usageContext, displayContext }}
                      />
                    ))}
                </div>
              )}
              <div
                data-cy={'usage-home-loaded'}
                className={styles['usageGrid']}
              >
                <UsageSummary
                  navigateToPage={navigateToPage}
                  navigateToChangePlan={navigateToChangePlan}
                />

                <div className={styles['rightColumn']}>
                  <UsageHistoryWidget />
                  {showShieldAd && <ShieldAd />}
                  <ArticleSection />
                  {hasStreamOnActive && (
                    <StreamCard page={DisplayZone.USAGE_PAGE} />
                  )}
                </div>

                <div className={styles['faq']}>
                  <UsageCommonQuestions />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalContext.Provider>
  );
};

const UsageHistoryWidget = () => {
  const userInfo = useSelector(selectUser);
  const config = useSelector(selectConfig);
  const { loading: usageLoading } = useSelector(selectCurrentUsage);
  const { t } = useTranslation();

  const isDataUsageLoaded =
    userInfo.loading === false && config.showDevicesGraph;

  const showChartLoading =
    (config.showDonutChart && usageLoading) ||
    !(config.showDonutChart || isDataUsageLoaded);

  if (showChartLoading) {
    return <DataUsageLoading chartTitle={t('UsageHistory:chartTitle')} />;
  }

  return (
    <div className={`${styles['usageChartContainer']}`}>
      <UsageHistory />
    </div>
  );
};

export default Usage;
