import React from 'react';

const IllustrationItalyUsage = () => (
  <svg
    width="248"
    height="160"
    viewBox="0 0 248 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6153_85577)">
      <g opacity="0.5" clipPath="url(#clip1_6153_85577)">
        <path
          d="M86.8303 7.08581L146.564 32.4183C155.51 36.2132 165.198 37.9571 174.912 37.5212L178.769 37.3396C193.699 36.6577 208.355 41.4957 219.921 50.9241C231.487 60.3524 239.146 73.7059 241.428 88.4182L241.762 90.5763C243.607 102.486 241.599 114.671 236.029 125.368C230.46 136.065 221.617 144.719 210.781 150.077C199.945 155.436 187.679 157.221 175.757 155.173C163.835 153.126 152.877 147.353 144.468 138.69L135.113 129.059C130.9 124.718 125.808 121.319 120.175 119.088C114.542 116.857 108.497 115.846 102.442 116.121L49.249 118.511C29.4762 119.398 18.8762 95.6892 32.6958 81.6111C35.3733 78.889 37.2843 75.5128 38.2368 71.8219C39.1894 68.1311 39.1499 64.2556 38.1224 60.5847L35.6623 51.7607C27.1984 21.406 57.723 -5.25776 86.8303 7.08581Z"
          fill="#FFE9B3"
        />
      </g>
      <g>
        <rect
          x="5.85718"
          y="138.286"
          width="237.714"
          height="1.12393"
          fill="#C3CDD5"
        />
      </g>
      <g clipPath="url(#clip2_6153_85577)">
        <path
          d="M94.7246 138.285H92.2979V137.903L94.905 74.9346H100.3L94.7246 138.285ZM93.0849 137.576H94.1836L99.7258 75.644H95.5773L93.0849 137.576Z"
          fill="#9FAFBC"
        />
        <path
          d="M97.4508 75.776L93.2607 138.026L93.8984 138.079L98.0884 75.8288L97.4508 75.776Z"
          fill="#9FAFBC"
        />
        <path
          d="M171.906 138.285H169.561L163.888 74.9346H169.364V75.262L171.906 138.285ZM170.135 137.576H171.234L168.659 75.644H164.609L170.135 137.576Z"
          fill="#9FAFBC"
        />
        <path
          d="M166.909 75.628L166.271 75.6814L170.51 137.927L171.148 137.874L166.909 75.628Z"
          fill="#9FAFBC"
        />
        <path
          d="M165.56 135.23H163.199L157.542 71.8972H162.92V72.2428L165.56 135.23ZM163.789 134.502H164.888L162.314 72.6611H158.247L163.789 134.502Z"
          fill="#677A89"
        />
        <path
          d="M160.553 72.5667L159.915 72.6201L164.154 134.866L164.792 134.812L160.553 72.5667Z"
          fill="#677A89"
        />
        <path
          d="M101.071 135.23H98.7258V134.848L101.349 71.8972H106.744L101.071 135.23ZM99.4309 134.502H100.497L106.023 72.6611H101.956L99.4309 134.502Z"
          fill="#677A89"
        />
        <path
          d="M103.801 72.7121L99.6108 134.962L100.249 135.015L104.439 72.7649L103.801 72.7121Z"
          fill="#677A89"
        />
        <path
          d="M165.527 90.5408L99.7422 78.9183L99.8406 78.209L165.626 89.8315L165.527 90.5408Z"
          fill="#9FAFBC"
        />
        <path
          d="M98.7747 90.9587L98.6599 90.2675L164.478 78.6086L164.593 79.318L98.7747 90.9587Z"
          fill="#9FAFBC"
        />
        <path
          d="M174.497 69.4966H89.1497V75.9717H174.497H175.136V69.4966H174.497Z"
          fill="#9FAFBC"
        />
        <path
          d="M175.136 75.7356H89.1497V76.9724H175.136V75.7356Z"
          fill="#677A89"
        />
      </g>
      <g clipPath="url(#clip3_6153_85577)">
        <path
          d="M185.462 98.0298L204.872 98.0922L213.345 61.3706L194.56 60.738L185.462 98.0298Z"
          fill="#677A89"
        />
        <path
          d="M186.322 97.6021L195.773 60.1856C195.773 60.1856 193.365 58.9117 193.202 59.6066L183.751 97.023L186.322 97.6021Z"
          fill="#9FAFBC"
        />
        <path
          d="M211.562 139.428H213.97L204.646 99.7312L201.694 100.524L211.562 139.428Z"
          fill="#9FAFBC"
        />
        <path
          d="M164.259 139.428H161.851L170.696 99.7312L173.656 100.061L164.259 139.428Z"
          fill="#677A89"
        />
        <path
          d="M194.343 139.428H196.751L193.075 99.7312L190.124 100.524L194.343 139.428Z"
          fill="#677A89"
        />
        <path
          d="M183.316 139.428H180.908L184.584 99.7312L187.535 100.061L183.316 139.428Z"
          fill="#9FAFBC"
        />
        <path
          d="M206.021 96.7378H163.626V100.613H205.333L206.021 96.7378Z"
          fill="#9FAFBC"
        />
        <path
          d="M211.859 59.7662L214.428 60.3401L205.538 98.8785L202.969 98.3046L211.859 59.7662Z"
          fill="#9FAFBC"
        />
        <path
          d="M162.15 82.9741H187.653V85.2904H162.15V82.9741Z"
          fill="#677A89"
        />
        <path
          d="M164.314 84.2212H166.686V96.8359H164.314V84.2212Z"
          fill="#677A89"
        />
        <path
          d="M182.119 82.7617H208.405V85.5395H182.119V82.7617Z"
          fill="#9FAFBC"
        />
        <path
          d="M184.309 84.4285H186.5V100.54H184.309V84.4285Z"
          fill="#9FAFBC"
        />
      </g>
      <g clipPath="url(#clip4_6153_85577)">
        <path
          d="M32.8736 96.9675H31.55V127.068H32.8736V96.9675Z"
          fill="#5EA215"
        />
        <path
          d="M31.0593 125.723C30.8934 124.436 31.1306 123.128 31.7385 121.981C32.3464 120.834 33.295 119.904 34.4536 119.319C39.4703 116.864 42.5016 117.184 46.4936 119.319C44.7291 118.759 42.8662 118.578 41.0268 118.787C39.1875 118.997 37.4131 119.593 35.8199 120.536C33.0233 122.094 33.6851 126.043 33.6851 126.043L31.0593 125.723Z"
          fill="#5EA215"
        />
        <path
          d="M31.678 125.723C31.678 125.723 23.9716 109.434 10.9282 112.146C0.937534 114.28 -0.428711 118.742 -0.428711 118.742C-0.428711 118.742 0.297109 108.879 17.61 110.95C28.2838 112.21 33.5993 125.701 33.5993 125.701L31.678 125.723Z"
          fill="#5EA215"
        />
        <path
          d="M33.4286 126.705C33.4286 126.705 37.6982 109.178 51.019 109.157C61.2872 109.157 63.5714 113.256 63.5714 113.256C63.5714 113.256 60.7749 103.756 44.1878 109.328C34.0264 112.743 31.55 127.089 31.55 127.089L33.4286 126.705Z"
          fill="#5EA215"
        />
        <path
          d="M31.1226 126.278C31.1226 126.278 34.4955 109.691 40.3234 102.668C46.1513 95.6443 50.5702 95.4735 54.0285 98.6756C53.1059 97.9653 51.989 97.5522 50.8262 97.4912C49.6633 97.4302 48.5094 97.7241 47.5175 98.3341C44.1446 100.469 36.8437 108.047 33.3854 126.278H31.1226Z"
          fill="#5EA215"
        />
        <path
          d="M30.7598 126.192C31.4915 118.468 32.6245 110.787 34.154 103.18C36.2888 94.2138 41.2414 92.2711 45.7885 93.5947C44.6456 93.3716 43.4619 93.5012 42.3943 93.9663C41.3268 94.4315 40.4259 95.2101 39.8111 96.1991C37.6764 99.6574 35.5416 100.319 32.1687 126.086L30.7598 126.192Z"
          fill="#5EA215"
        />
        <path
          d="M31.0381 126.918C31.0381 126.918 31.2089 110.758 32.4043 101.728C33.5998 92.6981 38.4457 90.2004 43.0781 91.0543C41.9202 90.9444 40.7561 91.1873 39.7388 91.7512C38.7215 92.3151 37.8987 93.1735 37.3783 94.2138C35.6705 97.8429 33.6211 98.7181 32.5751 124.613L31.0381 126.918Z"
          fill="#5EA215"
        />
        <path
          d="M32.8948 126.448C32.8948 126.448 33.0656 108.581 46.0236 105.421C56.2704 102.923 61.9916 108.645 61.9916 108.645C61.9916 108.645 54.2424 97.9922 39.2991 107.129C30.2264 112.722 30.9949 127.238 30.9949 127.238L32.8948 126.448Z"
          fill="#5EA215"
        />
        <path
          d="M34.0905 126.513C34.0905 126.513 36.8017 108.687 50.0799 107.535C60.2413 106.659 62.8884 110.566 62.8884 110.566C62.8884 110.566 59.3447 101.344 43.3554 108.303C33.5568 112.551 32.2546 127.089 32.2546 127.089L34.0905 126.513Z"
          fill="#BED733"
        />
        <path
          d="M32.2968 125.424C32.2968 125.424 34.8799 105.848 47.539 101.664C55.7791 98.9316 57.1454 103.351 57.1454 103.351C57.1454 103.351 56.1634 95.4947 43.5683 101.472C33.9192 106.062 30.7598 125.424 30.7598 125.424H32.2968Z"
          fill="#BED733"
        />
        <path
          d="M33.9195 126.022C33.9195 126.022 30.5466 109.776 24.7401 102.753C18.9335 95.7295 14.5146 95.5587 11.0349 98.7608C11.9588 98.0532 13.0753 97.6419 14.2375 97.5809C15.3996 97.52 16.553 97.8122 17.5459 98.4193C20.9188 100.554 28.2197 108.132 31.678 126.363L33.9195 126.022Z"
          fill="#5EA215"
        />
        <path
          d="M33.3431 125.766C32.6391 118.212 31.5346 110.701 30.0342 103.265C27.8995 94.2992 22.9468 92.3779 18.4211 93.6801C19.5577 93.4632 20.7333 93.596 21.7928 94.061C22.8523 94.5259 23.7459 95.3012 24.3558 96.2845C26.4905 99.7429 28.6253 100.405 31.9982 126.171L33.3431 125.766Z"
          fill="#5EA215"
        />
        <path
          d="M32.2547 126.534C32.2547 126.534 32.0839 108.666 19.1259 105.507C16.4054 104.681 13.5225 104.544 10.7357 105.106C7.94892 105.669 5.34522 106.914 3.15796 108.73C3.15796 108.73 10.8644 98.0564 25.701 107.215C34.7737 112.808 34.0052 127.324 34.0052 127.324L32.2547 126.534Z"
          fill="#5EA215"
        />
        <path
          d="M30.9741 126.598C30.9741 126.598 28.263 108.773 14.9848 107.62C4.82337 106.745 2.17627 110.651 2.17627 110.651C2.17627 110.651 5.71997 101.429 21.688 108.389C31.5078 112.637 32.8954 127.089 32.8954 127.089L30.9741 126.598Z"
          fill="#BED733"
        />
        <path
          d="M32.7668 125.531C32.7668 125.531 30.1837 105.934 17.5246 101.75C9.30581 99.017 7.91821 103.436 7.91821 103.436C7.91821 103.436 8.9002 95.6014 21.602 101.643C31.2298 106.233 34.1971 125.125 34.1971 125.125L32.7668 125.531Z"
          fill="#BED733"
        />
        <path
          d="M32.8953 126.022C32.7196 124.754 32.1681 123.568 31.3119 122.617C30.4558 121.666 29.3343 120.993 28.0921 120.685C22.5631 119.511 19.553 120.514 16.2869 123.652C17.8935 122.667 19.6908 122.034 21.5602 121.795C23.4296 121.555 25.3285 121.716 27.1314 122.265C30.2482 123.119 31.0594 127.217 31.0594 127.217L32.8953 126.022Z"
          fill="#BED733"
        />
        <path
          d="M31.0381 127.196C31.3427 125.848 32.0052 124.607 32.9556 123.604C33.9061 122.601 35.1095 121.873 36.439 121.496C41.8827 120.301 44.7646 121.325 48.1161 124.442C46.534 123.475 44.7659 122.851 42.9269 122.612C41.088 122.373 39.2193 122.524 37.4424 123.054C36.5278 123.355 35.7241 123.922 35.1344 124.683C34.5447 125.444 34.1962 126.363 34.1335 127.324L31.0381 127.196Z"
          fill="#BED733"
        />
        <path
          d="M39.5342 82.259C40.5812 82.131 41.6418 82.3391 42.5627 82.8533C43.4837 83.3675 44.2173 84.1612 44.6577 85.1196C44.3255 83.9862 43.609 83.0039 42.6311 82.3416C41.6532 81.6793 40.4751 81.3784 39.2994 81.4905C38.0633 81.6164 36.8755 82.0368 35.8354 82.7166C34.7954 83.3963 33.9335 84.3155 33.3221 85.3971C33.3221 85.3971 34.7951 77.4131 38.2534 77.2424C41.7117 77.0716 42.5229 78.4592 42.5229 78.4592C41.9977 77.7263 41.2898 77.1437 40.4696 76.7692C39.6495 76.3947 38.7456 76.2413 37.8478 76.3244C34.4535 76.3244 33.0019 80.4018 33.0019 80.4018C33.0019 80.4018 32.1266 76.1323 34.9018 74.9368C34.9018 74.9368 32.8951 74.9368 32.2974 76.4952L31.721 71.8201L31.5075 76.1963C31.2176 75.7491 30.8185 75.3831 30.3479 75.1329C29.8772 74.8826 29.3507 74.7564 28.8178 74.766C31.657 75.748 30.9525 80.167 30.9525 80.167C30.9525 80.167 29.2874 76.2177 25.8931 76.3244C24.996 76.2875 24.1044 76.4822 23.3043 76.8897C22.5042 77.2972 21.8224 77.9039 21.3248 78.6513C21.3248 78.6513 22.136 77.221 25.5943 77.1997C29.0526 77.1783 30.9952 85.0769 30.9952 85.0769C30.3203 84.0292 29.404 83.1586 28.323 82.5383C27.2421 81.918 26.0282 81.566 24.7831 81.5119C23.5971 81.4528 22.4285 81.8146 21.4833 82.5332C20.5381 83.2519 19.8771 84.2813 19.6169 85.4398C20.0037 84.4388 20.7024 83.589 21.6098 83.0161C22.5172 82.4432 23.585 82.1778 24.655 82.259C28.647 82.259 30.3761 86.8061 30.3761 86.8061L30.5896 89.0689L28.1346 86.9342C25.2741 85.3544 22.8404 88.5352 22.8404 88.5352C27.4088 84.9702 30.3761 91.4171 30.4829 93.4879C30.5566 94.7352 30.9539 95.9418 31.6356 96.9889H32.9378C33.3843 96.5806 33.7389 96.082 33.9781 95.5263C34.2174 94.9706 34.3357 94.3703 34.3254 93.7654C34.3254 91.6306 36.9298 85.0983 41.7117 88.3858C41.7117 88.3858 39.1073 85.3544 36.3321 87.0836L33.9839 89.3464V87.0836C33.9839 87.0836 35.5422 82.4512 39.5342 82.259Z"
          fill="#009DE0"
        />
        <path
          d="M45.6292 126.285C46.5377 128.222 47.0063 130.345 46.9997 132.494C46.9997 140.432 43.7527 146.857 32.9998 146.857C22.2468 146.857 18.9998 140.367 18.9998 132.494C18.9932 130.345 19.4618 128.222 20.3703 126.285H45.6292Z"
          fill="#9FAFBC"
        />
      </g>
      <g clipPath="url(#clip5_6153_85577)">
        <path
          d="M132.145 68.8042H155.489C155.746 68.8042 155.992 68.7021 156.173 68.5203C156.354 68.3386 156.456 68.0921 156.456 67.835L154.854 53.1593C154.854 52.9023 154.752 52.6558 154.571 52.474C154.389 52.2923 154.143 52.1902 153.887 52.1902H130.556C130.3 52.1902 130.054 52.2923 129.873 52.474C129.691 52.6558 129.589 52.9023 129.589 53.1593L131.178 67.835C131.178 68.0921 131.28 68.3386 131.461 68.5203C131.642 68.7021 131.888 68.8042 132.145 68.8042Z"
          fill="#F2F5F8"
        />
        <path
          d="M132.145 69.0951H155.489C155.823 69.0951 156.142 68.9623 156.378 68.7261C156.614 68.4898 156.746 68.1693 156.746 67.8352L155.144 53.1179C155.137 52.7886 155.001 52.4752 154.766 52.2449C154.531 52.0146 154.216 51.8857 153.887 51.8857H130.557C130.39 51.8857 130.224 51.9187 130.07 51.9827C129.916 52.0467 129.776 52.1405 129.658 52.2588C129.54 52.3771 129.446 52.5175 129.383 52.672C129.319 52.8266 129.286 52.9922 129.286 53.1595L130.888 67.8629C130.895 68.1922 131.031 68.5056 131.266 68.7359C131.501 68.9662 131.816 69.0951 132.145 69.0951ZM153.887 52.4811C154.067 52.4811 154.239 52.5526 154.366 52.6798C154.493 52.807 154.564 52.9796 154.564 53.1595L156.152 67.8629C156.145 68.0379 156.071 68.2035 155.945 68.3248C155.818 68.4461 155.65 68.5137 155.476 68.5136H132.131C132.043 68.5136 131.956 68.496 131.874 68.4617C131.793 68.4274 131.719 68.3772 131.657 68.3141C131.596 68.2509 131.547 68.176 131.515 68.0937C131.482 68.0115 131.466 67.9236 131.468 67.8352L129.907 53.1179C129.918 52.9453 129.994 52.7832 130.12 52.6649C130.245 52.5465 130.412 52.4808 130.584 52.4811H153.887Z"
          fill="#9FAFBC"
        />
        <path
          d="M131.565 69.0122H166.844C167.088 69.0122 167.286 69.2106 167.286 69.4552V69.8429C167.286 70.0876 167.088 70.2859 166.844 70.2859H131.565C131.321 70.2859 131.123 70.0876 131.123 69.8429V69.4552C131.123 69.2106 131.321 69.0122 131.565 69.0122Z"
          fill="#9FAFBC"
        />
        <path
          opacity="0.5"
          d="M131.441 70.2859H145.654V69.0122H131.441C131.356 69.0122 131.275 69.0458 131.216 69.1055C131.156 69.1652 131.123 69.2462 131.123 69.3306V69.9814C131.123 70.0225 131.131 70.0633 131.147 70.1012C131.163 70.1391 131.187 70.1733 131.217 70.2018C131.246 70.2302 131.282 70.2524 131.32 70.2669C131.358 70.2813 131.399 70.2878 131.441 70.2859Z"
          fill="#465967"
        />
        <path
          d="M132.421 54.1699H152.367C152.604 54.1699 152.796 54.3621 152.796 54.5991V54.613C152.796 54.85 152.604 55.0422 152.367 55.0422H132.421C132.185 55.0422 131.993 54.85 131.993 54.613V54.5991C131.993 54.3621 132.185 54.1699 132.421 54.1699Z"
          fill="#32424E"
        />
        <path
          d="M147.243 60.8156H153.334L152.754 56.3298H146.662L147.243 60.8156Z"
          fill="#C3CDD5"
        />
        <path
          d="M135.212 66.0492H153.97L153.583 61.5496H134.618L135.212 66.0492Z"
          fill="#C3CDD5"
        />
        <path
          d="M140.101 60.8156H146.193L145.613 56.3298H139.507L140.101 60.8156Z"
          fill="#C3CDD5"
        />
      </g>
      <g clipPath="url(#clip6_6153_85577)">
        <path
          d="M119.27 68.2582L119.478 67.6541C119.107 67.5243 119.062 66.6944 119.101 66.2993C119.242 65.5315 121.804 51.7738 121.95 50.1535C121.952 50.0142 121.985 49.8771 122.046 49.7521C122.107 49.6271 122.196 49.5174 122.305 49.4309C122.704 49.2306 123.161 49.1805 123.594 49.2898L123.751 48.6688C123.139 48.5126 122.49 48.5974 121.939 48.9059C121.751 49.0444 121.597 49.2234 121.488 49.4296C121.379 49.6357 121.318 49.8639 121.308 50.0971C121.167 51.6947 118.493 66.0509 118.493 66.192V66.2202C118.448 66.3952 118.319 67.9251 119.27 68.2582Z"
          fill="#111920"
        />
        <path
          d="M131.571 55.4092L127.377 53.7551L123.735 56.8827C123.735 56.8827 126.887 57.8254 131.571 55.4092Z"
          fill="#9FAFBC"
        />
        <path
          d="M127.371 57.4864C126.146 57.4864 125.153 56.4905 125.153 55.2621C125.153 54.0337 126.146 53.0378 127.371 53.0378C128.596 53.0378 129.59 54.0337 129.59 55.2621C129.59 56.4905 128.596 57.4864 127.371 57.4864Z"
          fill="#DAEE6B"
        />
        <path
          d="M123.222 48.3921H125.159V49.5101H123.222V48.3921Z"
          fill="#9FAFBC"
        />
        <path
          d="M127.676 47.8558L127.501 46.8959C126.511 46.7742 125.506 46.9408 124.607 47.3759L124.748 48.6407L127.676 47.8558Z"
          fill="#9FAFBC"
        />
        <path
          d="M127.675 47.8557L127.946 49.2727C128.193 49.9219 130.575 51.6155 131.571 55.4092C128.903 55.5325 126.266 56.0284 123.735 56.8826C123.222 53.2132 124.979 50.4582 124.894 49.809L124.714 48.3525C125.629 47.8927 126.661 47.7196 127.675 47.8557Z"
          fill="#111920"
        />
        <path
          d="M117.857 67.7385H127.079V69.7148H117.857V67.7385Z"
          fill="#111920"
        />
      </g>
      <g clipPath="url(#clip7_6153_85577)">
        <path
          d="M196.44 42.7485C197.029 42.66 197.564 42.3563 197.942 41.8961C198.319 41.4359 198.513 40.8519 198.485 40.257C198.485 38.0342 196.417 38.0456 196.143 38.5999C196.143 38.5999 194.205 39.1713 194.194 36.6685C194.194 36.6685 193.36 37.5028 190.863 37.2399C188.365 36.977 188.08 35.8628 185.857 36.7085C185.857 36.7085 184.52 44.9999 187.571 47.4228C187.916 47.6983 188.318 47.8936 188.748 47.9942V48.0285C188.783 49.1713 189.051 52.7428 188.365 53.2513L187.514 53.9085C187.228 54.7656 189.228 55.9085 191.8 55.9085C196.634 55.7599 197.228 54.4799 197.514 54.1942C198.657 53.0513 197.651 52.4799 197.651 52.4799C196.537 51.8628 196.44 42.7485 196.44 42.7485Z"
          fill="#C3AB98"
        />
        <g opacity="0.3">
          <path
            d="M188.657 44.3827L188.388 45.2055C188.534 45.4435 188.736 45.6424 188.976 45.7848C189.216 45.9272 189.487 46.0089 189.766 46.0227C190.657 46.0227 191.748 44.577 191.68 44.577C191.05 44.539 190.426 44.4258 189.823 44.2398C189.548 44.1712 189.206 44.4513 189.206 44.4513L188.657 44.3827Z"
            fill="#8E0D13"
          />
        </g>
        <path
          d="M188.657 44.3827C188.28 44.6113 188.388 45.2056 188.388 45.2056C188.388 45.2056 188.88 45.2056 189.565 45.1313C190.287 45.009 190.995 44.8176 191.68 44.5599C191.049 44.5219 190.426 44.4087 189.823 44.2227C189.548 44.1542 189.205 44.4342 189.205 44.4342C189.035 44.3496 188.84 44.3312 188.657 44.3827Z"
          fill="#590409"
        />
        <g opacity="0.3">
          <path
            d="M189.558 43.0998L188.301 42.7798C188.637 43.0216 189.007 43.2141 189.398 43.3512C189.649 43.3341 189.558 43.0998 189.558 43.0998Z"
            fill="black"
          />
        </g>
        <path
          d="M191.114 40.8056C191.121 40.7617 191.136 40.7195 191.159 40.6816C191.182 40.6436 191.213 40.6105 191.248 40.5843C191.284 40.558 191.325 40.539 191.368 40.5285C191.411 40.5179 191.456 40.516 191.5 40.5227C191.544 40.5295 191.586 40.5448 191.624 40.5678C191.662 40.5909 191.695 40.6212 191.721 40.657C191.747 40.6928 191.766 40.7335 191.777 40.7766C191.787 40.8197 191.789 40.8645 191.783 40.9084C191.774 40.9983 191.731 41.0815 191.663 41.141C191.595 41.2005 191.507 41.2318 191.417 41.2284C191.327 41.2211 191.242 41.1788 191.183 41.1105C191.123 41.0422 191.092 40.9534 191.097 40.8627C190.837 40.8254 190.595 40.7074 190.406 40.5256C190.615 40.6758 190.858 40.772 191.114 40.8056Z"
          fill="#1C262F"
        />
        <path
          d="M186.543 40.5371C186.763 40.6947 187.017 40.7967 187.285 40.8342C187.301 40.7556 187.344 40.6851 187.407 40.6357C187.47 40.5864 187.548 40.5615 187.628 40.5657C187.711 40.5802 187.786 40.6247 187.838 40.6907C187.89 40.7567 187.916 40.8395 187.911 40.9235C187.906 41.0075 187.871 41.0867 187.811 41.1461C187.752 41.2055 187.672 41.2409 187.588 41.2457C187.498 41.2398 187.414 41.1987 187.354 41.1313C187.295 41.064 187.264 40.9757 187.268 40.8857C186.996 40.8472 186.743 40.7254 186.543 40.5371Z"
          fill="#1C262F"
        />
        <path
          d="M188.291 39.7028V39.2628C188.291 39.2628 186.097 38.4 185.486 39.0171C186.452 39.0913 187.4 39.323 188.291 39.7028Z"
          fill="#E1E58F"
        />
        <path
          d="M189.971 39.68C190.505 39.3196 191.111 39.0815 191.747 38.9828C192.383 38.884 193.033 38.9269 193.651 39.1085C193.651 39.1085 192.303 37.9657 189.914 39.1428L189.971 39.68Z"
          fill="#E1E58F"
        />
        <path
          d="M196.44 42.7485C197.029 42.66 197.564 42.3563 197.942 41.8961C198.319 41.4359 198.513 40.8518 198.485 40.257C198.485 38.0342 196.417 38.0456 196.143 38.5999C196.143 38.5999 194.205 39.1713 194.194 36.6684C194.194 36.6684 193.36 37.5027 190.863 37.2399C188.365 36.977 188.08 35.8627 185.857 36.7085C185.857 36.7085 184.52 44.9999 187.571 47.4227L182.674 48.1713C182.674 48.1713 179.143 30.6285 191.914 29.7313C191.914 29.7313 198.577 29.1599 199.708 34.1427C199.708 34.1427 205.834 36.3313 203.651 44.9542L196.531 46.0456C196.497 45.9427 196.463 45.8399 196.434 45.737L196.44 42.7485Z"
          fill="#E1E58F"
        />
        <path
          d="M196.588 42.9256C196.175 42.9256 195.84 42.5905 195.84 42.177C195.84 41.7636 196.175 41.4285 196.588 41.4285C197.002 41.4285 197.337 41.7636 197.337 42.177C197.337 42.5905 197.002 42.9256 196.588 42.9256Z"
          fill="#570A46"
        />
        <g opacity="0.6">
          <path
            d="M188.291 39.7028V39.2628C188.291 39.2628 186.097 38.4 185.486 39.0171C186.452 39.0913 187.4 39.323 188.291 39.7028Z"
            fill="#131C24"
          />
        </g>
        <g opacity="0.6">
          <path
            d="M189.971 39.68C190.505 39.3196 191.111 39.0815 191.747 38.9828C192.383 38.884 193.033 38.9269 193.651 39.1085C193.651 39.1085 192.303 37.9657 189.914 39.1428L189.971 39.68Z"
            fill="#131C24"
          />
        </g>
        <g opacity="0.2">
          <path
            d="M188.828 49.5142L188.766 47.9885C192.308 47.9885 194.217 46.7085 194.217 46.7085C194.177 46.8971 195.211 48.7656 188.828 49.5142Z"
            fill="black"
          />
        </g>
        <path
          d="M144.099 137.963C149.323 137.963 152.141 136.876 152.141 136.876L152.028 135.199L153.538 133.031L154.082 136.614L156.762 136.255L156.345 130.559L152.219 133.545L148.887 135.987L143.89 136.411L144.099 137.963Z"
          fill="black"
        />
        <path
          d="M147.371 138.285C152.601 138.285 155.419 137.199 155.419 137.199L155.3 135.521L156.816 133.354L157.354 136.936L160.034 136.578L159.617 130.882L155.491 133.867L152.159 136.303L147.168 136.733L147.371 138.285Z"
          fill="black"
        />
        <path
          d="M144.236 136.381C144.236 136.381 149.699 136.858 151.15 135.969C152.016 134.631 153.049 132.983 153.604 132.709C154.159 132.434 156.345 130.559 156.345 130.559C156.488 130.046 156.474 129.5 156.303 128.995C155.882 128.143 155.365 127.342 154.762 126.607L154.243 124.063L149.819 124.833C149.819 124.833 148.505 130.583 147.729 132.595C146.833 134.984 144.809 134.208 144.391 134.984C144.27 135.193 144.193 135.425 144.167 135.666C144.14 135.906 144.163 136.15 144.236 136.381Z"
          fill="#03485C"
        />
        <path
          d="M147.508 136.673C147.508 136.673 152.977 137.157 154.422 136.267C155.288 134.93 156.327 133.282 156.876 133.001C157.827 132.336 158.742 131.62 159.616 130.858C159.764 130.345 159.752 129.799 159.581 129.294C159.158 128.441 158.639 127.64 158.034 126.905L157.515 124.362L153.091 125.138C153.091 125.138 151.777 130.888 151.007 132.9C150.105 135.288 148.087 134.512 147.663 135.288C147.544 135.496 147.469 135.727 147.442 135.965C147.415 136.203 147.438 136.444 147.508 136.673Z"
          fill="#006A85"
        />
        <path
          d="M151.198 132.583L152.547 132.404"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M151.526 131.622L152.876 131.443"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M151.705 130.679L153.055 130.5"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.034 129.717L153.383 129.538"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.213 128.774L153.562 128.595"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.541 127.813L153.891 127.634"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.72 126.87L154.07 126.69"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M147.92 132.267L149.275 132.082"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M148.248 131.3L149.604 131.121"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M148.427 130.362L149.783 130.177"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M148.762 129.395L150.111 129.216"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M148.935 128.452L150.29 128.272"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.269 127.49L150.619 127.311"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.448 126.547L150.798 126.368"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M148.756 85.3892C141.358 88.2373 143.836 98.8771 143.836 98.8771L147.418 122.189L157.025 122.057C155.867 112.797 153.944 97.7546 154.087 97.271C154.087 97.271 170.886 97.9517 183 97.271C186.153 96.9963 193.614 97.6053 194.42 90.9062C194.928 87.0866 194.754 83.2071 193.906 79.4484L193.596 76.7198L180.747 75.7764C180.747 75.7764 156.147 82.5412 148.756 85.3892Z"
          fill="#00819D"
        />
        <path
          d="M151.52 87.0312C144.129 89.8852 146.606 100.519 146.606 100.519L150.028 122.75L159.629 122.374C158.434 113.03 156.862 98.4537 157 97.9999C157 97.9999 173.451 99.258 185.571 98.5714C188.718 98.2967 196.39 99.2771 197.19 92.578C197.699 88.7587 197.527 84.8796 196.683 81.1202L196.366 78.3916L185.894 77.4363C185.894 77.4363 158.912 84.1832 151.52 87.0312Z"
          fill="#00A2C0"
        />
        <path
          d="M197.035 50.6217C198.653 51.7263 200.361 53.01 201.525 53.8459C204.242 58.4135 202.528 65.9903 201.704 67.6681C199.913 73.8597 196.743 79.711 196.844 80.8037C196.886 81.2157 197.65 85.6878 197.65 85.6878C191.894 86.7804 177.35 76.7734 177.35 76.7734L180.747 75.8301C180.687 73.9374 180.275 70.952 180.09 67.6024C180.09 67.5308 178.586 68.7966 178.389 68.7249C177.165 68.5219 174.687 63.3931 174.806 62.8975C174.926 62.4019 179.869 55.1714 179.869 55.1714C182.138 51.5113 187.211 50.0485 188.739 49.738C189.47 50.096 190.291 51.5994 192.428 51.8113C193.56 51.9236 195.286 51.8113 197.035 50.6217Z"
          fill="#C3CDD5"
        />
        <path
          d="M199.585 53.0039C194.372 53.3442 186.747 64.0319 184.061 67.9964L187.553 74.0925C187.553 74.0925 196.796 68.4621 199.871 65.6141"
          stroke="#1C262F"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M180.096 67.5785C179.989 65.6738 179.983 57.2253 185.118 53.3025"
          stroke="#1C262F"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.329 122.188L149.986 124.792L154.099 124.075L154.332 122.582L150.027 122.75L149.944 122.182L149.329 122.188Z"
          fill="#C3AB98"
        />
        <path
          d="M152.631 122.654L153.258 125.108L157.372 124.398L157.676 122.451L152.631 122.654Z"
          fill="#C3AB98"
        />
        <path
          d="M158.261 67.1963C158.106 66.5276 159.724 64.8378 159.999 64.808L174.8 62.969C174.897 63.4297 175.064 63.8727 175.296 64.2826C176.03 65.9066 177.415 68.4262 178.406 68.7188C178.199 68.8696 177.967 68.9828 177.72 69.0532L158.261 67.1963Z"
          fill="#C3AB98"
        />
        <path
          d="M161.056 67.5545L159.264 68.0381C158.705 68.3739 158.102 68.6329 157.473 68.8083C156.843 69.0402 156.174 69.1457 155.503 69.1188L154.494 69.1904L152.888 68.5934C152.856 68.4566 152.878 68.313 152.949 68.1919C153.02 68.0709 153.135 67.9817 153.27 67.9425C153.765 67.7754 154.464 68.1933 154.786 68.1216C155.049 68.0857 155.303 68.0049 155.539 67.8828C155.795 67.7336 156.07 67.4947 155.539 66.9156C155.137 66.8685 154.739 66.8007 154.345 66.7126C153.863 66.7687 153.376 66.7687 152.894 66.7126C152.583 66.8847 152.26 67.0324 151.926 67.1544C151.926 67.1544 150.971 68.0679 150.607 67.3574C150.607 67.3574 150.213 67.3574 150.183 67.035L150.625 66.5693C150.625 66.5693 150.422 66.5095 150.625 66.3245L151.102 65.9543C151.506 65.5695 151.937 65.2143 152.392 64.8915C152.512 64.752 152.666 64.6469 152.84 64.587C153.133 64.4578 153.44 64.3597 153.753 64.2944L154.757 64.4735C155.017 64.4091 155.29 64.4091 155.551 64.4735C156.31 64.6514 157.08 64.779 157.855 64.8557L158.906 65.0587C159.224 64.8958 159.576 64.8099 159.933 64.8079H160.667L161.056 67.5545Z"
          fill="#C3AB98"
        />
        <path
          d="M158.799 71.2087C158.954 70.6474 159.157 69.0652 159.993 69.0353C159.885 68.6472 160.351 68.5816 160.351 68.5816C161.069 68.5845 161.787 68.5245 162.495 68.4024C162.987 68.3092 163.497 68.3805 163.945 68.6054C163.945 68.6054 165.802 69.1547 166.859 69.4234C167.916 69.6921 172.549 70.1638 172.549 70.1638C172.549 70.1638 175.338 72.2237 171.845 71.955C170.001 72.0417 168.177 72.3771 166.423 72.9521C165.731 73.2507 163.981 72.564 163.981 72.564L161.486 71.9311C161.486 71.9311 159.85 72.5282 159.659 72.6536C159.467 72.779 159.157 72.5819 159.157 72.5819C159.13 72.617 159.097 72.6461 159.058 72.6674C159.02 72.6887 158.977 72.7017 158.933 72.7056C158.89 72.7095 158.845 72.7043 158.804 72.6902C158.762 72.6761 158.724 72.6535 158.691 72.6237C158.375 72.4028 158.799 71.2087 158.799 71.2087ZM159.754 71.6684L161.151 70.9698C160.912 70.9053 160.665 70.8751 160.417 70.8803C160.172 71.1222 159.95 71.386 159.754 71.6684Z"
          fill="#C3AB98"
        />
        <path
          d="M167.462 72.2416C167.307 71.5789 168.919 69.8892 169.2 69.8533L184.043 68.0024L187.41 73.8776C187.263 73.9817 187.097 74.0567 186.921 74.0985L167.462 72.2416Z"
          fill="#C3AB98"
        />
        <path
          d="M162.477 69.1787C161.647 69.1011 160.817 69.0593 159.981 69.0593C160.811 69.1369 161.641 69.1728 162.477 69.1787Z"
          fill="#624834"
        />
        <path
          d="M162.274 69.9071C161.635 69.8474 161.014 69.734 160.375 69.728C160.264 69.7351 160.156 69.7693 160.062 69.8276C159.967 69.8859 159.888 69.9665 159.832 70.0624L159.127 70.9879L158.685 71.7999L159.157 71.0117C159.157 71.0117 159.879 70.0923 159.885 70.0923C159.937 70.0048 160.009 69.931 160.096 69.877C160.182 69.823 160.28 69.7903 160.381 69.7818C160.978 69.7818 161.641 69.8833 162.274 69.9071Z"
          fill="#624834"
        />
        <path
          d="M162.56 71.0117C162.095 70.9759 161.635 70.958 161.175 70.9341C160.727 71.1371 160.291 71.3461 159.868 71.5849C159.653 71.6984 159.444 71.8178 159.27 71.9491C159.187 72.0208 159.026 72.0864 159.055 72.2237C159.071 72.3462 159.111 72.4642 159.175 72.5701C159.115 72.4639 159.08 72.3454 159.073 72.2237C159.073 72.0984 159.199 72.0506 159.288 71.979C159.491 71.8476 159.706 71.7342 159.915 71.6207L161.217 70.9819C161.623 70.9819 162.142 70.9819 162.59 71.0117H162.56Z"
          fill="#624834"
        />
        <path
          d="M154.864 65.3096C154.136 65.417 153.407 65.5424 152.679 65.6678L151.532 66.4918C151.234 66.7843 150.935 67.0888 150.649 67.3814C150.953 67.1067 151.246 66.7843 151.556 66.5216L152.715 65.7156C153.371 65.5962 154.165 65.4529 154.864 65.3096Z"
          fill="#624834"
        />
        <path
          d="M154.864 64.6648C154.028 64.8081 153.192 64.9693 152.362 65.1365L151.329 65.9127L150.631 66.6351L151.353 65.9366L152.392 65.1842C153.156 65.035 154.058 64.8499 154.864 64.6648Z"
          fill="#624834"
        />
        <path
          d="M154.524 64.5215C153.969 64.5155 153.415 64.5454 152.864 64.6111C153.419 64.6229 153.973 64.593 154.524 64.5215Z"
          fill="#624834"
        />
      </g>
      <g clipPath="url(#clip8_6153_85577)">
        <path
          d="M80.5364 37.6156C76.8116 35.9607 77.3755 31.8811 77.3755 31.8811C77.1616 32.0236 76.9212 32.1201 76.6692 32.1646C76.6692 32.1646 76.3389 33.7155 76.2307 34.6934C76.1225 35.6713 75.8662 39.3227 75.8662 39.3227C75.8662 39.3227 76.0371 40.2891 77.3527 41.5043C77.9775 42.0739 78.7278 42.4826 79.5406 42.6961C80.3533 42.9096 81.2046 42.9216 82.0228 42.731C84.8705 42.1524 84.4832 39.259 84.4832 39.259L84.449 38.3274L84.1529 38.3679H83.8169C82.6834 38.3548 81.5655 38.0985 80.5364 37.6156Z"
          fill="#8E7365"
        />
        <path
          d="M84.6883 35.029C83.7622 34.9633 82.8414 34.8357 81.9318 34.647C81.0205 34.4098 80.9522 34.0684 81.0205 33.7385C81.2255 32.6969 82.5924 32.7085 83.6973 32.9052C84.8022 33.102 85.8843 33.4434 85.8331 34.3056C85.8274 34.8612 85.5369 35 84.6883 35.029Z"
          fill="#8E7365"
        />
        <path
          d="M86.7671 26.8698C86.7387 26.4995 86.7159 26.2911 86.7159 26.2911C86.6884 26.0049 86.6427 25.7208 86.5792 25.4405V25.3595C86.5184 25.0954 86.4384 24.8362 86.34 24.5841C86.3271 24.5585 86.3175 24.5312 86.3115 24.5031C86.2122 24.2569 86.0941 24.0189 85.9584 23.7913V23.7451C85.817 23.5159 85.657 23.2991 85.48 23.097C84.9712 23.354 84.4077 23.4793 83.8398 23.4615C83.6826 23.4675 83.5258 23.4419 83.3784 23.3863C83.0538 23.2011 79.3348 22.7208 78.8222 22.8713C76.8061 23.4499 78.2527 24.5725 78.2527 25.4289C78.2527 26.2854 77.4496 26.7367 77.9964 29.6648C78.19 30.6716 77.4268 33.1367 79.9157 33.3566C80.6105 33.4261 80.1777 31.3544 84.0334 31.8405C86.3115 32.1241 86.7444 33.264 86.8013 33.4376C86.956 31.7585 86.9884 30.0699 86.8981 28.3859C86.8355 27.7494 86.7956 27.2344 86.7671 26.8698Z"
          fill="#8E7365"
        />
        <path
          d="M77.1136 30.9146C77.2234 30.9478 77.3358 30.9711 77.4496 30.9841C77.4496 30.9841 77.6717 27.6047 77.3471 26.9739C77.1705 26.6325 76.6124 26.6383 76.1682 27.055C76.0859 27.1326 76.0133 27.22 75.9517 27.3154L75.8948 27.4022C75.8315 27.5047 75.7781 27.6132 75.7353 27.7262C75.7194 27.7717 75.7061 27.8181 75.6954 27.8651C75.5887 28.201 75.5481 28.5549 75.5758 28.9067C75.5835 29.3655 75.7376 29.8093 76.0149 30.1714C76.2922 30.5335 76.6778 30.7943 77.1136 30.9146Z"
          fill="#8E7365"
        />
        <path
          opacity="0.3"
          d="M81.5559 33.3276C81.5559 33.3276 82.302 34.5891 83.2645 34.6354C84.227 34.6817 85.0699 34.2651 85.0984 33.588C84.5023 33.6245 83.9043 33.6129 83.3101 33.5533C82.3248 33.4607 81.5559 33.3276 81.5559 33.3276Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M84.6884 30.5039C84.8422 30.7701 83.9423 30.8627 83.5494 30.9842C83.3158 30.4055 84.2897 30.6254 84.6884 30.5039Z"
          fill="black"
        />
        <path
          d="M85.1952 28.49C85.384 28.49 85.537 28.3346 85.537 28.1429C85.537 27.9511 85.384 27.7957 85.1952 27.7957C85.0065 27.7957 84.8535 27.9511 84.8535 28.1429C84.8535 28.3346 85.0065 28.49 85.1952 28.49Z"
          fill="#1C262F"
        />
        <path
          d="M80.9523 28.629C81.141 28.629 81.294 28.4735 81.294 28.2818C81.294 28.09 81.141 27.9346 80.9523 27.9346C80.7636 27.9346 80.6106 28.09 80.6106 28.2818C80.6106 28.4735 80.7636 28.629 80.9523 28.629Z"
          fill="#1C262F"
        />
        <path
          d="M84.4093 26.3142C84.4093 26.0944 85.6566 26.1522 86.6875 26.1927C86.6875 26.1927 86.7558 26.4936 86.6875 26.5052C85.0415 26.916 84.4663 27.0029 84.4663 27.0029C84.4215 26.7763 84.4024 26.5452 84.4093 26.3142Z"
          fill="#30424E"
        />
        <path
          d="M82.1654 26.9972C80.8568 26.8682 79.5562 26.6653 78.2698 26.3896C78.2698 26.3896 82.2109 25.8515 82.2565 26.2334C82.2541 26.4906 82.2235 26.7468 82.1654 26.9972Z"
          fill="#30424E"
        />
        <path
          d="M81.1458 33.7845C81.3452 32.9049 82.6494 32.9165 83.6973 33.0843C84.7453 33.2521 85.7818 33.5357 85.7305 34.2416C85.7305 34.7161 85.4344 34.8203 84.6427 34.855C83.7623 34.7969 82.8857 34.6887 82.0172 34.5309C81.1458 34.3516 81.0832 34.0738 81.1458 33.7845ZM77.33 30.8912C77.2315 30.8787 77.1343 30.8575 77.0395 30.8275C76.6462 30.6934 76.3058 30.4342 76.0688 30.0881C75.8319 29.7421 75.7109 29.3277 75.7239 28.9064C75.6811 28.5974 75.7037 28.2828 75.7901 27.9834C75.8765 27.6841 76.0248 27.4069 76.2251 27.1704C76.6067 26.7827 77.0908 26.7711 77.2445 27.101C77.5236 27.6738 77.33 30.8623 77.33 30.8623V30.8912ZM86.3229 22.489C86.4763 22.2722 86.5901 22.0292 86.6589 21.7715C87.0291 20.3942 85.9071 17.2232 83.0879 17.7787C81.1287 18.1606 79.7562 19.5147 78.5943 19.6304C76.9614 19.6383 75.3883 20.2562 74.1748 21.3664C72.4263 23.4264 73.7021 26.2908 74.2488 28.7733C74.2488 28.7733 75.2113 32.1816 76.003 32.3031C76.2063 32.3409 76.4147 32.3409 76.6181 32.3031C76.8701 32.262 77.1107 32.1673 77.3243 32.0254C77.3243 32.0254 76.7946 36.0413 80.5136 37.6962C81.5441 38.1819 82.664 38.4403 83.7998 38.4543C85.6508 38.4022 86.9152 37.2275 86.6475 34.3168L86.7614 33.3679C86.7101 33.2001 86.2773 32.0832 84.0163 31.8055C80.1947 31.331 80.5991 33.3621 79.9327 33.2868C77.4667 33.0843 78.2241 30.6655 78.0362 29.676C77.4667 26.7827 78.2526 26.366 78.2868 25.527C78.321 24.6879 76.8573 23.6174 78.8563 23.0272C79.3575 22.8825 83.0424 23.3512 83.3613 23.5306C83.5068 23.5858 83.6617 23.6114 83.8169 23.6058C84.3803 23.6205 84.9389 23.4974 85.4458 23.247C86.1871 24.1233 86.6305 25.2192 86.7101 26.3718C86.7101 26.3718 86.8013 27.2109 86.8582 28.4203L86.9892 25.498C87.0632 23.7447 86.619 22.8593 86.3229 22.4601V22.489Z"
          fill="#30424E"
        />
        <path
          opacity="0.3"
          d="M83.3442 23.4791C83.4232 23.5097 83.5053 23.5311 83.5891 23.5427C83.9217 23.5809 84.2581 23.5653 84.5858 23.4964C84.5858 23.4964 84.1643 21.2686 83.0993 20.2964C82.9232 20.1044 82.7084 19.9533 82.4696 19.8535C82.2307 19.7536 81.9734 19.7073 81.7154 19.7178C80.7927 19.7178 80.0068 20.9793 78.9987 21.1124C78.289 21.2169 77.5661 21.1835 76.8687 21.014C77.0129 21.2336 77.2021 21.4191 77.4232 21.5579C77.6443 21.6967 77.8922 21.7855 78.1501 21.8183C79.8587 22.0208 81.049 20.8809 81.7552 20.9503C82.4614 21.0197 83.0765 21.2801 83.3442 23.4791Z"
          fill="white"
        />
        <path
          d="M87.6074 152.502L87.9826 151.792L88.4632 149.505C88.4632 149.505 87.8126 147.796 88.3225 147.623C88.8324 147.45 89.6355 149.262 90.4502 149.262C91.2649 149.262 92.7947 148.295 92.7947 148.295C92.7947 148.295 93.1522 146.605 93.6329 146.374L95.1276 148.084C96.3778 149.129 97.8007 149.901 99.3185 150.357C101.769 151.062 104.459 151.228 104.459 152.086C104.459 152.944 104.019 153.034 104.019 153.034C104.019 153.034 100.104 154.468 99.506 154.43C98.9081 154.392 91.3645 154.167 91.3645 154.167L88.5042 153.072L87.6074 152.502Z"
          fill="#DEE4E8"
        />
        <path
          d="M69.0735 152.502L69.4428 151.792L69.9234 149.505C69.9234 149.505 69.2728 147.796 69.7886 147.623C70.3044 147.45 71.0957 149.262 71.9162 149.262C72.7368 149.262 74.2315 148.295 74.2315 148.295C74.2315 148.295 74.589 146.605 75.0638 146.374L76.5643 148.084C77.812 149.13 79.2331 149.902 80.7493 150.357C83.2053 151.062 85.8957 151.228 85.8957 152.086C85.8957 152.944 85.4502 153.034 85.4502 153.034C85.4502 153.034 81.5348 154.468 80.9369 154.43C80.3391 154.392 72.8013 154.167 72.8013 154.167L69.941 153.072L69.0735 152.502Z"
          fill="#DEE4E8"
        />
        <path
          d="M87.9005 152.797C87.9005 152.797 86.8688 153.655 86.8337 154.545C86.8337 154.545 86.1948 155.736 93.1757 155.352C93.1757 155.352 99.3887 155.717 101.176 154.808C102.419 154.488 104.582 153.655 104.635 152.944C104.635 152.944 104.781 151.958 104.43 152.073C103.597 152.342 101.499 153.886 99.1192 154.078C95.7489 154.34 90.4326 152.592 90.4326 152.592L89.5357 153.06C88.9824 153.052 88.4325 152.964 87.9005 152.797Z"
          fill="black"
        />
        <path
          d="M69.3607 152.797C69.3607 152.797 68.329 153.655 68.2939 154.545C68.2939 154.545 67.655 155.736 74.6359 155.352C74.6359 155.352 80.8548 155.717 82.6425 154.808C83.8852 154.488 86.048 153.655 86.0949 152.944C86.0949 152.944 86.2415 151.958 85.8956 152.073C85.0575 152.342 82.965 153.886 80.5794 154.078C77.2091 154.34 71.8986 152.592 71.8986 152.592L70.996 153.06C70.4426 153.052 69.8927 152.964 69.3607 152.797Z"
          fill="black"
        />
        <path
          d="M88.4631 149.505L97.0969 153.879L93.7911 154.417L87.9297 151.907L88.4631 149.505Z"
          fill="#C3CDD5"
        />
        <path
          d="M69.9233 149.505L78.5571 153.879L75.2513 154.417L69.3899 151.907L69.9233 149.505Z"
          fill="#C3CDD5"
        />
        <path
          d="M88.1409 147.898C88.176 147.565 88.4633 147.552 88.6684 147.725C89.2194 148.161 89.5769 148.891 90.21 149.185C91.605 149.461 92.6718 147.796 94.0433 147.943C94.4894 148.005 94.9176 148.174 95.2977 148.436C94.9134 148.19 94.486 148.035 94.0433 147.981C92.6659 147.853 91.5991 149.55 90.1982 149.262C89.5417 148.955 89.2018 148.231 88.6567 147.77C88.4632 147.597 88.1819 147.604 88.1409 147.93V147.898Z"
          fill="#465967"
        />
        <path
          d="M69.6011 147.898C69.6362 147.565 69.9293 147.552 70.1286 147.725C70.6796 148.161 71.0371 148.891 71.676 149.185C73.071 149.461 74.132 147.796 75.5094 147.943C75.9534 148.006 76.3794 148.175 76.7579 148.436C76.3736 148.19 75.9462 148.035 75.5035 147.981C74.132 147.853 73.0593 149.55 71.6584 149.262C71.002 148.955 70.662 148.231 70.1169 147.77C69.9293 147.597 69.6421 147.604 69.6011 147.93V147.898Z"
          fill="#465967"
        />
        <path
          d="M87.6075 152.503C87.2058 153.06 86.9432 153.722 86.8455 154.424C86.8455 154.424 86.3121 155.128 92.9355 154.923C92.9355 154.923 99.1016 155.429 100.889 154.513C102.132 154.199 104.295 153.361 104.348 152.656C102.561 153.288 100.707 153.667 98.832 153.783C95.4558 154.052 90.1454 152.304 90.1454 152.304L89.2428 152.765C88.6894 152.758 88.1394 152.669 87.6075 152.503Z"
          fill="#677A89"
        />
        <path
          d="M69.0735 152.503C68.6715 153.061 68.4071 153.722 68.3056 154.424C68.3056 154.424 67.7781 155.128 74.4015 154.923C74.4015 154.923 80.5618 155.429 82.3495 154.513C83.5921 154.199 85.755 153.361 85.8077 152.656C84.0209 153.288 82.1672 153.667 80.2921 153.783C76.9218 154.052 71.6056 152.304 71.6056 152.304L70.7029 152.765C70.1514 152.758 69.6034 152.669 69.0735 152.503Z"
          fill="#677A89"
        />
        <path
          d="M96.1706 148.872L94.9456 149.685"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M97.2199 149.506L95.9949 150.319"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M98.3569 150.095L97.1318 150.908"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M77.631 148.872L76.406 149.685"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M78.6801 149.506L77.4609 150.319"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M79.8173 150.095L78.5981 150.908"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M67.5217 79.227L69.8825 112.458L70.043 146.009L75.9303 146.033L81.6806 93.1241L87.1263 121.846L88.5303 146.033L94.0466 146.056L95.4626 120.959L93.9967 78.3875L67.5217 79.227Z"
          fill="#005B75"
        />
        <path
          d="M81.5159 90.3765L82.2047 79.3269"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M84.1119 79.2212C84.1119 79.2212 84.3533 87.4409 81.7217 87.3176"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M88.2979 79.3328C88.2979 79.3328 89.0631 82.7615 93.7671 82.9024"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M76.4527 78.634C76.4527 78.634 75.6873 82.0628 70.9834 82.2037"
          stroke="black"
          strokeWidth="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M94.6264 79.4852L67.5803 79.3149L66.4028 68.0247L70.6534 54.198L67.5214 41.1992C69.3795 40.0601 71.3529 39.7082 73.4087 38.9815C75.3515 38.3592 75.7636 40.2674 80.8738 40.3261C84.8123 40.3672 84.2059 39.0595 87.0907 39.6642C93.2193 40.9442 94.5498 78.8452 94.6264 79.4852Z"
          fill="#DAEE6B"
        />
        <path
          d="M74.5862 49.5186L71.4012 60.486L72.5434 77.1073L68.2986 78.6338C68.2986 78.6338 63.3946 63.2395 63.059 61.214C62.7234 59.1884 63.7831 42.8196 66.0026 41.6571C68.2221 40.4946 70.8596 40.0308 72.9849 41.528C76.058 43.7003 74.5862 49.5186 74.5862 49.5186Z"
          fill="#DAEE6B"
        />
        <path
          d="M87.02 39.6404C93.284 40.9379 94.8578 44.0464 94.9932 44.3341C95.1286 44.6218 95.3623 59.2351 95.3623 59.2351L103.334 75.5042L99.2125 77.8527L88.48 60.5797L82.0806 43.107L87.02 39.6404Z"
          fill="#DAEE6B"
        />
        <path
          d="M72.19 74.7236L68.0984 76.0799"
          stroke="#465967"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M71.2303 46.542L69.8704 58.9537L72.5432 77.1073"
          stroke="#465967"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M93.914 69.2576L92.3362 56.2764L93.025 48.1155"
          stroke="#465967"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M86.7826 40.5886C86.7826 40.5886 86.1938 42.6905 82.6615 42.8843C80.1881 42.9982 77.7279 42.4637 75.5261 41.3343"
          stroke="#465967"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M102.468 73.426L98.0234 75.9976"
          stroke="#465967"
          strokeWidth="0.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M89.0549 148.09L89.4477 146.073H93.6268V146.355C93.6268 146.355 93.451 146.425 93.24 146.867C93.0526 147.31 92.9035 147.771 92.7945 148.244L91.7805 148.826C91.3283 149.096 90.8179 149.226 90.3034 149.204C89.9107 149.127 89.4828 148.564 89.3656 148.429L89.0549 148.09Z"
          fill="#8E7365"
        />
        <path
          d="M75.1283 146.438L75.0814 146.015H70.9491L70.5447 148.116L70.826 148.449C71.0629 148.736 71.3398 148.98 71.6466 149.172C72.093 149.283 72.561 149.215 72.9654 148.98C73.2702 148.839 74.2725 148.244 74.2725 148.244C74.2725 148.244 74.6301 146.611 75.0873 146.374L75.1283 146.438Z"
          fill="#8E7365"
        />
        <path
          d="M73.7441 78.986C73.7441 78.986 74.4447 79.7258 74.5978 79.9606C74.7508 80.1955 75.0629 81.3521 75.1865 81.6046C75.3101 81.8571 75.2277 82.403 75.4043 82.8199C75.581 83.2368 75.993 83.6889 75.2336 83.7887C74.4741 83.8885 73.7441 79.6436 73.803 79.4263C73.8262 79.3715 73.8381 79.3127 73.8381 79.2532C73.8381 79.1937 73.8262 79.1348 73.803 79.08C73.803 79.08 73.5851 78.7923 73.7441 78.986Z"
          fill="#8E7365"
        />
        <path
          d="M72.0309 77.2893C72.3017 77.5829 73.8383 78.8687 73.8501 79.2033C73.9825 79.8431 74.1596 80.4729 74.3799 81.088C74.4741 81.364 74.7331 82.4501 74.8567 82.7613C74.9804 83.0725 75.3631 84.1821 75.3748 84.3172C75.3748 84.5403 75.7045 85.5853 75.7398 85.873C75.7752 86.1607 75.7046 86.5599 75.3395 86.4954C74.9745 86.4308 74.6036 85.2213 74.5212 84.9923L74.062 83.9532L73.5852 82.8846C73.438 82.5499 73.332 82.5265 73.2613 82.5617C73.1907 82.5969 73.2613 82.9609 73.3261 83.0549C73.3908 83.1488 73.8265 84.4111 73.8501 84.464C73.9972 85.1039 74.692 85.7028 74.692 85.9318C74.692 86.1607 74.4329 86.2546 74.3622 86.2488L73.8089 86.149C73.5872 86.0604 73.3866 85.9263 73.2201 85.7556C72.9901 85.7889 72.7554 85.7662 72.5361 85.6895C72.3167 85.6128 72.1191 85.4845 71.9603 85.3153C71.8177 85.4023 71.6478 85.4337 71.4834 85.4033C71.1337 85.2061 70.8242 84.9452 70.5709 84.6342C70.203 83.8754 69.892 83.0904 69.6406 82.2857C69.4464 81.5048 69.1049 79.1505 69.0107 78.534C69.0107 78.487 69.0107 78.4401 69.0107 78.3931C69.0107 78.3461 72.0191 77.2835 72.0309 77.2893Z"
          fill="#8E7365"
        />
        <path
          d="M104.164 82.8494C104.217 83.2304 104.334 83.5996 104.511 83.9414C104.511 83.9414 104.411 84.8455 104.794 85.2271L105.159 85.462L105.353 83.7887L104.164 82.8494Z"
          fill="#8E7365"
        />
        <path
          d="M100.019 77.3892C100.008 77.5017 99.9902 77.6134 99.966 77.7239C99.8436 78.3393 99.8537 78.9737 99.9955 79.585C100.172 80.0782 100.743 81.3464 100.837 81.64C100.931 81.9335 101.785 84.4816 101.785 84.4816C101.785 84.4816 102.833 84.7634 102.668 83.46C102.596 82.7565 102.438 82.0645 102.197 81.3992C102.197 81.3992 102.45 81.0235 103.016 81.6106C103.76 82.3331 104.439 83.1189 105.047 83.959C105.229 84.3642 104.658 85.8555 104.994 86.7303C105.329 87.6051 105.765 87.1765 105.765 87.1765C105.765 87.1765 106.354 84.5168 106.424 84.1235C106.495 83.7301 104.858 80.4011 104.782 80.1428C104.705 79.8844 102.839 76.4029 102.774 76.1563C102.734 76.0599 102.685 75.9676 102.627 75.8804C102.627 75.8804 100.031 77.3892 100.019 77.3892Z"
          fill="#8E7365"
        />
        <path
          d="M104.164 82.8494C104.482 83.2075 104.753 83.5891 105.082 83.959C105.035 84.4169 105.006 84.8749 104.941 85.327C104.941 84.9043 104.988 84.3289 105.029 83.9883C104.741 83.6067 104.441 83.2369 104.164 82.8494Z"
          fill="#624834"
        />
        <path
          d="M104.511 83.9415C104.516 83.7693 104.56 83.6005 104.64 83.4482C104.633 83.6199 104.589 83.7881 104.511 83.9415Z"
          fill="#624834"
        />
        <path
          d="M75.2162 83.7888C74.8571 82.6146 74.3802 81.0059 73.9446 79.9021C74.481 81.1583 74.9064 82.4588 75.2162 83.7888Z"
          fill="#624834"
        />
        <path
          d="M73.2145 85.7262C72.402 84.8455 72.296 83.5128 71.8015 82.4795C72.3902 83.4658 72.4315 84.8279 73.2145 85.7262Z"
          fill="#624834"
        />
        <path
          d="M72.0254 85.4093C71.6854 85.0101 71.4291 84.5469 71.2718 84.0472C71.0659 83.5692 70.9024 83.0741 70.7832 82.5676C71.1835 83.5011 71.3719 84.6167 72.0254 85.4093Z"
          fill="#624834"
        />
      </g>
      <path
        d="M182.119 82.1904H208.405V84.9682H182.119V82.1904Z"
        fill="#9FAFBC"
      />
      <path d="M184.31 83.8572H186.5V99.9683H184.31V83.8572Z" fill="#9FAFBC" />
    </g>
    <defs>
      <clipPath id="clip0_6153_85577">
        <rect width="248" height="160" fill="white" />
      </clipPath>
      <clipPath id="clip1_6153_85577">
        <rect
          width="216"
          height="152"
          fill="white"
          transform="translate(26.4287 4)"
        />
      </clipPath>
      <clipPath id="clip2_6153_85577">
        <rect
          width="90.2857"
          height="90.2857"
          fill="white"
          transform="translate(87 48)"
        />
      </clipPath>
      <clipPath id="clip3_6153_85577">
        <rect
          width="52.5714"
          height="80"
          fill="white"
          transform="matrix(-1 0 0 1 214.428 59.4285)"
        />
      </clipPath>
      <clipPath id="clip4_6153_85577">
        <rect
          width="105.143"
          height="105.143"
          fill="white"
          transform="translate(-19.8572 41.7141)"
        />
      </clipPath>
      <clipPath id="clip5_6153_85577">
        <rect
          width="38"
          height="32"
          fill="white"
          transform="matrix(-1 0 0 1 167.286 38.2856)"
        />
      </clipPath>
      <clipPath id="clip6_6153_85577">
        <rect
          width="22.8571"
          height="22.8571"
          fill="white"
          transform="matrix(-1 0 0 1 136.143 46.8572)"
        />
      </clipPath>
      <clipPath id="clip7_6153_85577">
        <rect
          width="102.857"
          height="123.429"
          fill="white"
          transform="matrix(-1 0 0 1 211.571 19.4285)"
        />
      </clipPath>
      <clipPath id="clip8_6153_85577">
        <rect
          width="73.1429"
          height="146.286"
          fill="white"
          transform="translate(45.2856 9.14282)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IllustrationItalyUsage;
