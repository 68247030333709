import { Spinner } from '@vst/beam';
import Content from '../Content';

interface ChartLoadingProps {
  loadingMsg?: string;
}

export const ChartLoading = (props: ChartLoadingProps): JSX.Element | null => {
  const { loadingMsg = '' } = props;
  return (
    <Content tabIndex={0} isCentered data-cy="chart-loading">
      <Spinner description={loadingMsg} size="small" />
    </Content>
  );
};
