import styles from './styles.module.scss';

export const UnleashedUsageSummaryLoading = () => {
  return (
    <div
      data-cy="unleashed-usage-summary-loading"
      className={styles['containerWhite']}
    >
      <div className={styles['loadingTitile']} />
      <div className={styles['loadingThermometer']} />
      <div className={styles['loadingAlert']} />
    </div>
  );
};
