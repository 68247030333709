import { useSelector } from 'react-redux';

import { Locale } from '@mfe/shared/schema-types';
import {
  selectPlanInfo,
  shouldMockTierDataForUser,
} from '@mfe/to-be-migrated/redux/plan';
import { DataRemaining, DaysLeft, UsageBar } from '@mfe/shared/components';

import { UnleashedUsageSummary } from '../unleashed-usage-summary';

import { TotalDataUsed } from './total-data-used';
import { UsageSummaryCard } from './usage-summary-card';
import { UnlimitedContent } from './unlimited-content';

import styles from './styles.module.scss';

import { hasUnlimitedAverage } from '@mfe/shared/util';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';

type Props = {
  isLoading: boolean;
  handleChangePlan: () => void;
  handlePlanDetailsClick: () => void;
  handleNavigateToPlanPage: () => void;
  userLocale: Locale;
};

export const LocalizedUsageSummary = ({
  isLoading,
  handleChangePlan,
  handlePlanDetailsClick,
  handleNavigateToPlanPage,
  userLocale,
}: Props) => {
  const commonProps = {
    isLoading,
    handlePlanDetailsClick,
    handleNavigateToPlanPage,
    id: 'usageSummary',
  };

  const { env, getMockTier, showUnleashedUsage } = useSelector(selectConfig);
  const { user } = useSelector(selectUser);

  const {
    data: {
      characteristics: { isUnleashed, hasUnlimitedUsageMeter, usageMeter },
    },
  } = useSelector(selectPlanInfo);

  const hasUnlimitedAverageMeter =
    showUnleashedUsage && hasUnlimitedAverage(usageMeter);

  const hasUnleashedPlan = hasUnlimitedAverageMeter || isUnleashed;
  switch (userLocale) {
    case Locale.EnUs:
      return (showUnleashedUsage && hasUnleashedPlan) ||
        shouldMockTierDataForUser(user, env, getMockTier) ? (
        <UnleashedUsageSummary />
      ) : (
        <UsageSummaryCard
          handleChangePlan={handleChangePlan}
          {...commonProps}
          showFooter={!isUnleashed}
        >
          {hasUnlimitedUsageMeter ? <UnlimitedContent /> : <UsageData />}
        </UsageSummaryCard>
      );

    case Locale.PtBr:
      return (
        <UsageSummaryCard {...commonProps}>
          <UsageData />
        </UsageSummaryCard>
      );

    case Locale.ItIt:
      return (
        <UsageSummaryCard showFooter={false} {...commonProps}>
          <TotalDataUsed />
          <DaysLeft />
        </UsageSummaryCard>
      );

    default:
      return null;
  }
};

const UsageData = () => (
  <>
    <div className={styles['remaining']}>
      <DataRemaining />
      <DaysLeft />
    </div>
    <UsageBar />
  </>
);
