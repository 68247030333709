import React from 'react';
import { useTranslation } from 'react-i18next';
import { Txt, Icon } from '@vst/beam';
import { Error, Info } from '@vst/beam-icons/icons';
import { useSelector } from 'react-redux';

import { StreamOnStatus } from '@mfe/shared/schema-types';
import { selectStreamOn } from '@mfe/to-be-migrated/redux/streamOn';

import BodyTitle from './title';
import ErrorReplaceHub from './error-replace-hub';
import Shipping from './shipping';
import styles from '../styles.module.scss';

const StreamCardBody = ({ state }: { state: StreamOnStatus }): JSX.Element => {
  const { t } = useTranslation('StreamOn');
  const { streamOn } = useSelector(selectStreamOn);

  const showDefaultMessage = state === StreamOnStatus.Pending;
  const showErrorBubble = state === StreamOnStatus.Unplugged;

  return (
    <>
      <BodyTitle state={state} />
      <div id="StreamOnBody" className={styles['streamBody']}>
        {state === StreamOnStatus.ErrorReplaceHub && <ErrorReplaceHub />}
        {state === StreamOnStatus.Shipping && <Shipping />}
        {showDefaultMessage && (
          <Txt variant="bodySmallRegular">
            {t('Body.body', { context: state })}
          </Txt>
        )}
        {showErrorBubble && (
          <div className={styles['streamNotif'] + ` ${styles['error']}`}>
            <Icon
              icon={Error}
              mr="4px"
              color="alert_400"
              style={{ width: '16px' }}
            />
            <div className={styles['infoText']}>
              <Txt variant="smallRegular">
                {t('Body.body', { context: state })}
              </Txt>
            </div>
          </div>
        )}
        {state === StreamOnStatus.Error && (
          <div className={styles['streamNotif'] + ` ${styles['error']}`}>
            <Icon
              icon={Error}
              style={{ width: '16px' }}
              mr="4px"
              color="alert_400"
            />
            <div className={styles['infoText']}>
              <Txt variant="smallBold" component="p">
                {t('Body.error1')}
              </Txt>
              <Txt variant="smallRegular">{t('Body.error2')}</Txt>
            </div>
          </div>
        )}
        {state === StreamOnStatus.Delivered && (
          <>
            <Txt variant="bodySmallRegular">{t('Body.deliveredTo')}</Txt>
            <Txt variant="bodySmallBold" pb="16px">
              {streamOn?.shippingInfo?.deliveryAddress}
            </Txt>

            <div className={styles['streamNotif']}>
              <Icon
                icon={Info}
                style={{ width: '16px' }}
                mr="4px"
                color="teal_600"
              />
              <div className={styles['infoText']}>
                <Txt variant="smallRegular">{t('Body.hubSetUp')}</Txt>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StreamCardBody;
