import React from 'react';
import { EmptyState, Surface } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { useTranslation } from 'react-i18next';
import { useScreenResolution } from '@mfe/shared/util';
import { UsageToggle } from '../UsageToggle/UsageToggle';
import { useSelector } from 'react-redux';
import { selectConfig } from '@mfe/shared/redux/config';
import { useStoreContext } from '../../context/store';

export const ErrorState = (): JSX.Element | null => {
  const { isExtraSmall } = useScreenResolution();
  const { t } = useTranslation('Error');
  const { showDonutChart } = useSelector(selectConfig);
  const { showMonthly } = useStoreContext();
  const initialMode = showMonthly ? 'monthly' : 'daily';
  return (
    <Surface
      mt="40px"
      mb="16px"
      py={isExtraSmall ? '32px' : '12px'}
      px={isExtraSmall ? '24px' : '32px'}
    >
      {showDonutChart && (
        <div style={{ display: 'flex', margin: '0px auto 48px auto' }}>
          <UsageToggle initialMode={initialMode} />
        </div>
      )}
      <EmptyState
        title={t('title')}
        icon={Alert}
        description={t('message_graph')}
      />
    </Surface>
  );
};
