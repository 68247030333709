import { addDays, formatISO, subDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Graph } from '@vst/beam-icons/illustrative-icons';
import {
  ConventionalNativeSelect,
  EmptyState,
  Txt,
  IllustrativeIcon,
  Button,
  colors,
} from '@vst/beam';

import {
  fetchUnleashedUsage,
  UnleashedTimeInterval,
  selectHistoricalUsageInfo,
} from '@mfe/to-be-migrated/redux/usageHistory';
import { useScreenResolution } from '@mfe/shared/util';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import { ChartLoading } from '../../components/ChartLoading';
import { UnleashedBarChart } from '../../components/BarCharts/UnleashedBarChart';

import styles from './DailyUsageChart.module.scss';

export const UnleashedDailyUsageChart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('UsageHistory');

  const { isExtraLarge, isLarge } = useScreenResolution();
  const isMobile = !isExtraLarge && !isLarge;

  const { loading, errors, unleashedUsage } = useSelector(
    selectHistoricalUsageInfo
  );

  const {
    userInfo: { isPreInstall },
  } = useSelector(selectUserInfo);

  const [selectedInterval, setSelectedInterval] = useState(
    UnleashedTimeInterval.LAST_30_DAYS
  );

  const options = [
    { label: t('lastThirtyDays'), value: UnleashedTimeInterval.LAST_30_DAYS },
    { label: t('lastSixtyDays'), value: UnleashedTimeInterval.LAST_60_DAYS },
    { label: t('lastNinetyDays'), value: UnleashedTimeInterval.LAST_90_DAYS },
  ];

  useEffect(() => {
    const { start, end } = getInterval(new Date(), 30);
    dispatch(fetchUnleashedUsage({ start, end, selectedInterval }));
  }, []);

  const mapSelectionToInterval = useCallback(
    (selectedInterval: `${UnleashedTimeInterval}`) => {
      if (selectedInterval === UnleashedTimeInterval.LAST_30_DAYS) {
        return getInterval(new Date(), 30);
      }
      if (selectedInterval === UnleashedTimeInterval.LAST_60_DAYS) {
        return getInterval(new Date(), 60);
      }
      return getInterval(new Date(), 90);
    },
    []
  );

  const handleChange: React.FormEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const target = e.target as HTMLInputElement;
      const selectedInterval = target.value as UnleashedTimeInterval;
      const { start, end } = mapSelectionToInterval(selectedInterval);
      dispatch(fetchUnleashedUsage({ start, end, selectedInterval }));
      setSelectedInterval(selectedInterval);
    },
    [selectedInterval]
  );

  const chartData = unleashedUsage[selectedInterval];
  const hasNoDataUsed = chartData.length === 0;

  if (errors) {
    return (
      <div
        className={styles['UsageChartWrapper']}
        data-cy="unleashed-error-wrapper"
      >
        <CardHeaderWithTitle />
        {loading ? (
          <Loading />
        ) : (
          <>
            <EmptyState
              pt="72px"
              px={isMobile ? '16px' : '24px'}
              icon={Alert}
              title={t('unleashedErrorTitle')}
              description={t('unleashedErrorDescription')}
            />
            <Button
              data-cy="refresh-button"
              variant="secondary"
              mb="72px"
              style={{ alignSelf: 'center' }}
              onClick={() => {
                const { start, end } = mapSelectionToInterval(selectedInterval);
                dispatch(fetchUnleashedUsage({ start, end, selectedInterval }));
              }}
            >
              {t('refresh')}
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className={styles['UsageChartWrapper']}
      data-cy="unleashed-chart-wrapper"
    >
      <CardHeaderWithTitle>
        {!hasNoDataUsed && !isPreInstall && (
          <div className={styles.BillCycleDropdown}>
            <ConventionalNativeSelect
              data-cy="unleashed-interval-dropdown"
              id="unleashed-dropdown"
              fluid={true}
              width="294px"
              onChange={handleChange}
              options={options}
              value={selectedInterval}
              style={{ color: colors.gray[600] }}
            />
          </div>
        )}
      </CardHeaderWithTitle>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.UsageChart}>
          {isPreInstall || hasNoDataUsed ? (
            <NoDataUsed>
              {isPreInstall ? t('unleashedPreinstall') : t('unleashedNoUsage')}
            </NoDataUsed>
          ) : (
            <UnleashedBarChart
              selectedInterval={selectedInterval}
              data={chartData}
            />
          )}
        </div>
      )}
    </div>
  );
};

const CardHeaderWithTitle = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation('UsageHistory');
  return (
    <div className={styles.TitleSection}>
      <Txt p="24px" color="regular" variant="heading5">
        {t('chartTitle')}
      </Txt>
      {children}
    </div>
  );
};

const Loading = () => (
  <div style={{ height: 453, display: 'flex' }}>
    <ChartLoading />
  </div>
);

const NoDataUsed = ({ children }: { children: React.ReactNode }) => (
  <div data-cy="no-data" className={styles['NoDataUsed']}>
    <IllustrativeIcon icon={Graph} color="teal_600" />
    <Txt variant="bodyLargeRegular" color="subtle">
      {children}
    </Txt>
  </div>
);

const getInterval = (date: Date, days: number) => ({
  // Adding 1 day to the current date in order to also display usage data from the current day
  end: formatISO(addDays(date, 1), { representation: 'date' }),
  start: formatISO(subDays(date, days), { representation: 'date' }),
});
