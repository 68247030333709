import React from 'react';
import { useTranslation } from 'react-i18next';
import { Txt, InlineLink, Icon } from '@vst/beam';
import { Info } from '@vst/beam-icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectStreamOn } from '@mfe/to-be-migrated/redux/streamOn';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import { structuredEvent } from '@mfe/to-be-migrated/redux/analytics';
import { useStreamOnAnalyticsContext } from '../utils';
import styles from '../styles.module.scss';

const Shipping = (): JSX.Element => {
  const { t } = useTranslation('StreamOn');
  const dispatch = useDispatch();
  const { displayContext, streamOnStatus } = useStreamOnAnalyticsContext();
  const { streamOn } = useSelector(selectStreamOn);

  const openShipping = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const url = streamOn.shippingInfo?.trackingUrl as string;
    dispatch(
      structuredEvent({
        category: 'Stream',
        action: 'tracking_number_selected',
        params: {
          property: streamOnStatus ?? '',
          context: [displayContext],
        },
      })
    );

    dispatch(openInNewTab(url));
  };

  return (
    <>
      <Txt variant="bodySmallRegular" mb="24px">
        {t('Body.trackPackage')}{' '}
        <InlineLink
          id="shippingLink"
          variant="primary"
          href=""
          onClick={openShipping}
        >
          {streamOn.shippingInfo?.trackingNumber}
        </InlineLink>
      </Txt>

      <div className={styles['streamNotif']}>
        <Icon icon={Info} mr="4px" color="teal_600" style={{ width: '16px' }} />
        <div className={styles['infoText']}>
          <Txt variant="smallRegular">{t('Body.body_SHIPPING')}</Txt>
        </div>
      </div>
    </>
  );
};

export default Shipping;
