import React from 'react';
import { parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EmptyState, Txt } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import { selectStreamOn } from '@mfe/to-be-migrated/redux/streamOn';
import { StreamOnStatus } from '@mfe/shared/schema-types';

import { selectCurrentUsage } from '@mfe/to-be-migrated/redux/plan';

import styles from '../styles.module.scss';

const parseBold = (rawString: string): JSX.Element => {
  const splitString = rawString.split('*');
  return (
    <Txt variant="paragraphRegular">
      {splitString[0]}
      <Txt variant="paragraphBold" component={'span'}>
        {splitString[1]}
      </Txt>
      {splitString[2]}
    </Txt>
  );
};

const BodyTitle = ({ state }: { state: StreamOnStatus }): JSX.Element => {
  const { t } = useTranslation(['StreamOn', 'Error']);

  const {
    data: { startDate, endDate },
  } = useSelector(selectCurrentUsage);
  const { streamOn } = useSelector(selectStreamOn);

  const showStreamOnSavings =
    state === StreamOnStatus.Active ||
    state === StreamOnStatus.Unplugged ||
    state === StreamOnStatus.ErrorReplaceHub ||
    state === StreamOnStatus.Error;

  let pb = '0px';
  switch (state) {
    case StreamOnStatus.Pending:
      pb = '8px';
      break;
    case StreamOnStatus.Shipping:
    case StreamOnStatus.Delivered:
      pb = '16px';
      break;
    case StreamOnStatus.Unplugged:
    case StreamOnStatus.Error:
    case StreamOnStatus.ErrorReplaceHub:
      pb = '24px';
      break;
    default:
      pb = '0px';
  }

  if (state === StreamOnStatus.RequestErrored) {
    return (
      <div
        id="StreamOnHeader"
        className={`${styles['streamHeader']} ${styles['timeout']}`}
        style={{ paddingBottom: pb }}
      >
        <EmptyState
          icon={Alert}
          title={t('Error:title')}
          description={t('Error:message', { error: t('Error:usage_stream') })}
        />
      </div>
    );
  }

  return (
    <div
      id="StreamOnHeader"
      className={styles['streamHeader']}
      style={{ paddingBottom: pb }}
    >
      <Txt variant="paragraphRegular" id="header">
        {showStreamOnSavings && startDate && endDate
          ? parseBold(
              t('StreamOn:Header.header', {
                context: state,
                amount: streamOn.dataSavedGB,
                startDate: parseISO(startDate),
                endDate: parseISO(endDate),
              })
            )
          : t('StreamOn:Header.header', { context: state })}
      </Txt>
    </div>
  );
};

export default BodyTitle;
