import { AemFeature } from '@mfe/shared/components';
import { ISectionAlertProps } from '@vst/beam';

export enum ALERT_COLOR {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
}

export const getPercentageScoreAndColor = (
  tier: number | null
): {
  beakPosition: string;
  color: ISectionAlertProps['variant'] | undefined;
  aemFeature: AemFeature | null;
} => {
  if (tier === 0) {
    return {
      beakPosition: '30%',
      color: 'success',
      aemFeature: AemFeature.UNLEASHED_USAGE_GREEN,
    };
  }
  if (tier === 1) {
    return {
      beakPosition: '70%',
      color: 'warning',
      aemFeature: AemFeature.UNLEASHED_USAGE_YELLOW,
    };
  }

  if (tier === 2) {
    return {
      beakPosition: '95%',
      color: 'error',
      aemFeature: AemFeature.UNLEASHED_USAGE_RED,
    };
  }
  return {
    beakPosition: '',
    color: undefined,
    aemFeature: null,
  };
};
