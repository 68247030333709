import { useMemo } from 'react';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import accessibility from 'highcharts/modules/accessibility';

import {
  UsageData,
  UnleashedTimeInterval,
} from '@mfe/to-be-migrated/redux/usageHistory';
import { useScreenResolution } from '@mfe/shared/util';

import { getOptions } from '../../../utils';
import styles from './styles.module.scss';

if (typeof Highcharts === 'object') {
  accessibility(Highcharts);
}

type Props = {
  selectedInterval: `${UnleashedTimeInterval}`;
  data: UsageData[] | [];
};

const MAX_DAYS_TO_DISPLAY = 30;
const MAX_BARS_IN_SMALLEST_SCREEN = 7;

export const UnleashedBarChart = ({
  selectedInterval,
  data,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('UsageHistory');
  const { isExtraSmall, isSmall, isLarge } = useScreenResolution();

  const chartData = useMemo(
    () =>
      data.map((dailyUsage: { date: string; usage: number }) => {
        const date = new Date(`${dailyUsage.date}T00:00:00`);
        return {
          name: t('tooltipDate', { date }),
          x: date.getTime(),
          y: dailyUsage.usage,
        };
      }),
    [data, t]
  );

  let scrollPositionX = 0.0;

  if (chartData?.length > MAX_BARS_IN_SMALLEST_SCREEN) {
    const barsToShow = chartData.length;
    scrollPositionX = barsToShow / MAX_DAYS_TO_DISPLAY;
  }

  const scrollProps =
    isExtraSmall ||
    isSmall ||
    (isLarge && selectedInterval !== UnleashedTimeInterval.LAST_30_DAYS)
      ? { scrollablePlotArea: { minWidth: 575, scrollPositionX } }
      : {};

  const barWidths = {
    [UnleashedTimeInterval.LAST_90_DAYS]: 5,
    [UnleashedTimeInterval.LAST_60_DAYS]: 7,
    [UnleashedTimeInterval.LAST_30_DAYS]: 10,
  };

  const barWidth = barWidths[selectedInterval];

  const options = getOptions(t, chartData, barWidth, scrollProps);
  const totalDataUsage = chartData.reduce((acc, current) => acc + current.y, 0);

  return (
    <div data-cy="unleashed-bar-chart" className={styles['unleashedBarChart']}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div
        data-cy="total-data-used"
        className={styles['unleashedTotalDataUsed']}
      >
        <Txt
          component="span"
          variant="bodySmallBold"
          color="regular"
          ml="8px"
          mr="4px"
        >
          {t('totalDataUsage', { totalDataUsage })}
        </Txt>
        <Txt component="span" variant="bodySmallRegular" color="regular">
          {t('totalDataUsed')}
        </Txt>
      </div>
    </div>
  );
};
