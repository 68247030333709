import { useSelector } from 'react-redux';

import {
  articleQuestionsBrazil,
  articleQuestionsItaly,
  articleQuestionsSMB,
  BrazilQuestionSpeedElement,
} from '@mfe/to-be-migrated/shared-platform-react/pages/Overview/InternationalArticleCards';
import Illustration1 from '@mfe/to-be-migrated/shared-platform-react/pages/Overview/img/Illustration1';
import {
  useQuestionArticleCardProps,
  ArticleCard,
} from '@mfe/shared/components';
import { AccountType, Locale } from '@mfe/shared/schema-types';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import IllustrationItalyUsage from './italy-illustration';
import IllustrationBrazilSpeed from './brazil-illustration';
import { useTranslation } from 'react-i18next';

const useArticleSMBProps = () => {
  const { t } = useTranslation('Overview');

  return useQuestionArticleCardProps({
    title: t(articleQuestionsSMB[0].title),
    caption: t(articleQuestionsSMB[0].caption),
    linkText: t(articleQuestionsSMB[0].linkText),
    websiteLink: t(articleQuestionsSMB[0].websiteLink),
  });
};

const useArticleItalyProps = () => {
  const { t } = useTranslation('Overview');

  return useQuestionArticleCardProps({
    title: t(articleQuestionsItaly[0].title),
    caption: t(articleQuestionsItaly[0].caption),
    linkText: t(articleQuestionsItaly[0].linkText),
    websiteLink: t(articleQuestionsItaly[0].websiteLink),
  });
};

const useArticleBrazilProps = () => {
  const { t } = useTranslation('Overview');

  return useQuestionArticleCardProps({
    title: t(articleQuestionsBrazil[1].title),
    caption: t(articleQuestionsBrazil[1].caption),
  });
};

export const ArticleSection = () => {
  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const isBrazil = userLocale === Locale.PtBr;
  const isItaly = userLocale === Locale.ItIt;

  const articleSMB = useArticleSMBProps();
  const articleItaly = useArticleItalyProps();
  const articleBrazil = useArticleBrazilProps();

  if (isBrazil) {
    return (
      <ArticleCard
        {...articleBrazil}
        image={<IllustrationBrazilSpeed />}
        extraContent={<BrazilQuestionSpeedElement />}
      />
    );
  }

  if (isItaly) {
    return <ArticleCard {...articleItaly} image={<IllustrationItalyUsage />} />;
  }

  if (accountType === AccountType.Smb) {
    return <ArticleCard {...articleSMB} image={<Illustration1 />} />;
  }

  return null;
};
