import React from 'react';
import { EmptyState, Surface } from '@vst/beam';
import { PieGraph } from '@vst/beam-icons/illustrative-icons';
import { useTranslation } from 'react-i18next';
import { useScreenResolution } from '@mfe/shared/util';

export const PreInstallState = (): JSX.Element => {
  const { isExtraSmall } = useScreenResolution();
  const { t } = useTranslation('Usage');

  return (
    <Surface
      mt={'72px'}
      mb={'12px'}
      py={isExtraSmall ? '32px' : '12px'}
      px={isExtraSmall ? '24px' : '32px'}
    >
      <EmptyState
        title=""
        icon={PieGraph}
        color-={'teal_300'}
        description={t('preinstall')}
      />
    </Surface>
  );
};
