import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@vst/beam-icons/icons';
import { Icon, SectionAlert, Txt } from '@vst/beam';

import {
  BuyMoreProduct,
  getBuyMoreHistory,
  selectBuyMoreHistory,
} from '@mfe/to-be-migrated/redux/buyMoreHistory';
import { parseDateStringWithoutTime } from '@mfe/shared/util';
import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';
import {
  AnalyticsAction,
  Categories,
  selectAnalytics,
  structuredEvent,
} from '@mfe/to-be-migrated/redux/analytics';

import { BuyMoreHistoryUsageBar } from './usage-bar';

import styles from './styles.module.scss';

export const BuyMoreHistory = (): JSX.Element | null => {
  const { t } = useTranslation('Usage');
  const dispatch = useDispatch();

  const {
    data: {
      characteristics: { hasUnlimitedUsageMeter },
      usage: { endDate, buyMoreDataCap },
    },
  } = useSelector(selectPlanInfo);

  const { data, total, loading } = useSelector(selectBuyMoreHistory);
  const { usageContext, display_platform } = useSelector(selectAnalytics);

  const renewalDate = endDate ? parseDateStringWithoutTime(endDate) : null;

  useEffect(() => {
    if (buyMoreDataCap && !total) {
      dispatch(getBuyMoreHistory());
    }
  }, [buyMoreDataCap, total, dispatch]);

  const dispatchToggleEvent = useCallback(() => {
    const displayContext = {
      schema: 'display_context',
      data: {
        display_zone: 'usage-summary',
        display_platform,
        display_location: 'body',
      },
    };

    dispatch(
      structuredEvent({
        category: Categories.UsageSummaryBuyMore,
        action: AnalyticsAction.EXPANDED,
        params: {
          context: [usageContext, displayContext],
        },
      })
    );
  }, [dispatch, usageContext, display_platform]);

  const handleToggle = (event: React.ChangeEvent<HTMLDetailsElement>) => {
    if (event.target.open) {
      dispatchToggleEvent();
    }
  };

  if (loading) {
    return <div className={styles['buyMoreHistoryLoading']} />;
  }

  return data.length > 0 ? (
    <details
      className={styles['accordionDetails']}
      data-cy="buy-more-history"
      onToggle={handleToggle}
    >
      <summary
        className={styles['accordionSummary']}
        data-cy="buy-more-expand-history"
      >
        <Txt color="subtle" variant="bodySmallRegular" data-cy="total-buy-more">
          <strong>{`${total}GB`}</strong> {t('buyMoreHistory.total')}
        </Txt>
        <Icon icon={ExpandMore} color="gray_600" />
      </summary>

      {hasUnlimitedUsageMeter && <BuyMoreHistoryUsageBar />}

      <div className={styles['productList']}>
        <TitleRow />
        {data.map((product, index) => (
          <ProductRow key={index} data={product} />
        ))}
      </div>
      {renewalDate && (
        <SectionAlert
          variant="infoPrimary"
          label={t('buyMoreHistory.infoAlert', { renewalDate })}
          style={{ boxSizing: 'border-box' }}
          mt="16px"
        />
      )}
    </details>
  ) : null;
};

type RowProps = {
  data: BuyMoreProduct;
};

const ProductRow = ({ data }: RowProps): JSX.Element => {
  const { dataAmount, price, purchaseDate } = data;
  const { t } = useTranslation('Usage');

  return (
    <div className={styles['listRow']} data-cy="buy-more-history-list">
      <Txt variant="smallRegular" color="subtle">
        {t('buyMoreHistory.product.name', { amount: dataAmount })}
      </Txt>
      <Txt variant="smallRegular" color="subtle">
        {price}
      </Txt>
      <Txt variant="smallRegular" color="subtle">
        {t('buyMoreHistory.product.date', { date: new Date(purchaseDate) })}
      </Txt>
    </div>
  );
};

const TitleRow = () => {
  const { t } = useTranslation('Usage');
  const titles = [
    t('buyMoreHistory.title.purchase'),
    t('buyMoreHistory.title.price'),
    t('buyMoreHistory.title.date'),
  ];

  return (
    <div className={styles['listRow']}>
      {titles.map((title: string) => (
        <Txt key={title} variant="tinyBold" color="subtle">
          {title}
        </Txt>
      ))}
    </div>
  );
};
