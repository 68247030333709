import { EmptyState } from '@vst/beam';
import styles from './styles.module.scss';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { useTranslation } from 'react-i18next';

export const UnleashedUsageSummaryError = () => {
  const { t } = useTranslation('Usage', { keyPrefix: 'unleashed' });

  return (
    <div
      data-cy="unleashed-usage-summary-loading"
      className={styles['containerWhite']}
    >
      <EmptyState title={t('error')} icon={Alert} />
    </div>
  );
};
