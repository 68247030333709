import { Button, Txt, StandAloneLink } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType } from '@mfe/shared/schema-types';
import { Issuer, selectConfig } from '@mfe/shared/redux/config';
import { Locale } from '@mfe/shared/schema-types';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';

import {
  PlanChangePendingBadge,
  useHasPlanChangePending,
} from '@mfe/shared/components';

import styles from './styles.module.scss';

interface HeaderProps {
  handleChangePlan?: () => void;
  handlePlanDetailsClick: () => void;
  isChangePlanDisabled: boolean;
}

export const Header = ({
  handleChangePlan,
  handlePlanDetailsClick,
  isChangePlanDisabled,
}: HeaderProps) => {
  const { t } = useTranslation('Usage');
  const { showChangePlanSMB } = useSelector(selectConfig);

  const {
    data: {
      characteristics: { name },
    },
  } = useSelector(selectPlanInfo);

  const {
    userInfo: { accountType, isPreInstall },
  } = useSelector(selectUserInfo);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const { issuer } = useSelector(selectConfig);
  const isSalesforce = issuer === Issuer.Salesforce;
  const canFlowBeAccessed = userLocale === Locale.EnUs;
  const showChangePlanForSMB =
    accountType === AccountType.Smb ? showChangePlanSMB : true;

  const showChangePlan =
    !isPreInstall &&
    canFlowBeAccessed &&
    handleChangePlan &&
    showChangePlanForSMB;

  const hasPlanChangePending = useHasPlanChangePending();

  return (
    <div className={`${styles['header']} usage-summary-header`}>
      <div className={styles['planNameColumn']}>
        <Txt data-cy="plan-name" variant="headerCapsMedium" component="p">
          {name}
        </Txt>
        {hasPlanChangePending ? (
          <PlanChangePendingBadge mt="8px" />
        ) : (
          showChangePlan && (
            <ChangePlanButton
              handleChangePlan={handleChangePlan}
              isChangePlanDisabled={isChangePlanDisabled}
            />
          )
        )}
      </div>
      <Button
        data-cy="plan-details-button"
        buttonSize="small"
        variant="secondary"
        onClick={handlePlanDetailsClick}
        theme={'light'}
        disabled={isSalesforce}
      >
        <Txt variant="labelSmall" style={{ whiteSpace: 'nowrap' }}>
          {t('buttons.planDetails')}
        </Txt>
      </Button>
    </div>
  );
};

interface ChangePlanButtonProps {
  handleChangePlan: () => void;
  isChangePlanDisabled?: boolean;
}

const ChangePlanButton = (props: ChangePlanButtonProps) => {
  const { t } = useTranslation('Usage');
  const { handleChangePlan, isChangePlanDisabled } = props;

  return isChangePlanDisabled ? (
    <Txt
      className={styles['changePlanDisabled']}
      data-cy="change-plan-disabled"
      variant="smallRegular"
      color="disabled"
    >
      {t('buttons.changePlan')}
    </Txt>
  ) : (
    <StandAloneLink
      data-cy="change-plan-enabled"
      linkSize="small"
      href=""
      onClick={(e) => {
        e.preventDefault();
        handleChangePlan();
      }}
    >
      {t('buttons.changePlan')}
    </StandAloneLink>
  );
};
