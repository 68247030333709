import React from 'react';
import { Txt } from '@vst/beam';
import styles from './Loading.module.scss';
import { ErrorState } from '../ErrorState';

interface ErrorApiProps {
  chartTitle: string;
}

export const ErrorApiComponent = (props: ErrorApiProps): JSX.Element => {
  return (
    <div
      data-cy="error-state-wrapper"
      className={styles['chartLoadingContainer']}
    >
      <div data-cy="chart-title" className={styles['chartLoadingTitle']}>
        <Txt variant="heading5">{props.chartTitle}</Txt>
      </div>
      <ErrorState />
    </div>
  );
};

export default ErrorApiComponent;
