import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const StreamCardLoading = ({
  testID = 'stream-on-loading',
}: {
  testID?: string;
}): JSX.Element | null => {
  const { t } = useTranslation('StreamOn');
  return (
    <div
      className={styles['cardStyle']}
      style={{ paddingBottom: '7px' }}
      data-cy={testID}
    >
      <div className={styles['divider']} style={{ alignItems: 'center' }}>
        <div className={styles['streamOnTitle']} style={{ fontWeight: 'bold' }}>
          {t('cardTitle')}
        </div>
      </div>
      <div style={{ display: 'block', margin: 24 }}>
        <div className={styles['streamOnBody']}></div>
        <div className={styles['streamOnBody']}></div>
        <div className={styles['streamOnBody']}></div>
      </div>
    </div>
  );
};

export default StreamCardLoading;
