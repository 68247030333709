import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Txt, colors } from '@vst/beam';

import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';

import { IconWithTooltip } from '@mfe/shared/components';

export const UnlimitedContent = () => {
  const { t } = useTranslation('Usage');

  const {
    data: {
      characteristics: { inflectionPointText },
      usage: { monthlyDataUsed, monthlyDataCap, hasBreachedDataAllowance },
    },
  } = useSelector(selectPlanInfo);

  if (monthlyDataUsed && hasBreachedDataAllowance) {
    return (
      <div data-cy="unlimited-message">
        <Txt variant="heading3" component="span">
          {t('unlimited.message', {
            amount: monthlyDataCap,
          })}
        </Txt>
        <IconWithTooltip
          toolTipText={inflectionPointText}
          toolTipPosition="center"
        />
      </div>
    );
  }

  return (
    <div data-cy="unlimited-dataUsed">
      <Txt variant="display2" color="inherit" data-cy="unlimited-dataUsedGB">
        {t('unlimited.data', {
          amount: monthlyDataUsed.toFixed(1),
        })}
      </Txt>

      <Txt
        variant="bodySmallRegular"
        component="span"
        style={{
          color: colors.gray[600],
        }}
      >
        {t('unlimited.total')}
      </Txt>
      <IconWithTooltip
        toolTipPosition="center"
        toolTipText={inflectionPointText}
      />
    </div>
  );
};
