import { colors } from '@vst/beam';
import { TFunction } from 'i18next';

type ChartDataPoint = {
  name: string;
  x: number;
  y: number;
};

type ChartData = ChartDataPoint[];

export const getOptions = (
  t: TFunction,
  data: ChartData,
  barWidth: number,
  scrollProps = {}
) => {
  const yAxisLabelsConfig =
    data?.length === 0
      ? { min: 0, max: 1 }
      : { min: undefined, max: undefined };

  return {
    accessibility: {
      enabled: true,
      pointDescriptionFormatter: (point: { name: string; y: number }) =>
        t('accessibilityBarChart', {
          pointName: point.name,
          pointY: point.y.toFixed(2),
        }),
      keyboardNavigation: { enabled: true },
    },
    animation: false,
    animationLimit: 50,
    boostThreshold: 1,
    turboThreshold: 2,
    chart: {
      height: 408,
      type: 'column',
      animation: false,
      reflow: true,
      style: { fontFamily: 'Uni Neue' },
      ...scrollProps,
    },
    credits: {
      enabled: false,
      href: null, // to override default href of highcharts.com
    },
    colors: [colors.gray[700]],
    title: null,
    tooltip: {
      animation: false,
      distance: 10,
      headerFormat: `<span style="font-family:Source Sans Pro;font-weight:400;font-size:14px">{point.point.name}</span><table>`,
      pointFormat:
        '<tr><td style="font-family:Uni Neue;font-weight:800;font-size:18px;padding:0">{point.y:,.2f} GB</td></tr>',
      footerFormat: '</table>',
      borderColor: colors.white,
      shared: true,
      outside: true,
      borderRadius: 8,
      backgroundColor: colors.white,
      useHTML: true,
    },
    yAxis: {
      ...yAxisLabelsConfig,
      gridLineColor: colors.gray[200],
      title: { text: null },
      labels: {
        enabled: data?.length !== 0,
        format: '{value:.2f} GB',
        style: {
          color: 'var(--beam-color-subtle)',
          fontSize: '12px',
          fontFamily: 'Source Sans Pro',
          fontWeight: 400,
          lineHeight: '16px',
        },
      },
    },
    xAxis: {
      startOfWeek: 0,
      startOnTick: false,
      tickInterval: 3600 * 1000 * 24 * 7,
      tickmarkPlacement: 'on',
      labels: {
        // TODO: Update this to use date-fns as part of https://jira.viasat.com/browse/VGFB-544
        formatter: function (): string {
          // Even though we are passing a GMT Time which is referring to a Local date at 00:00 HR,
          //  the vaule contaied here is transformed to a GMT Time at 00:00 HR
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore Seems to work
          const _timeDt = new Date(this.value);
          const endYear = _timeDt.getFullYear();
          const endMonth = `00${_timeDt.getUTCMonth() + 1}`.slice(-2);
          const endDay = `00${_timeDt.getUTCDate()}`.slice(-2);
          const localdate = `${endYear}-${endMonth}-${endDay}T00:00:00`; // YYYY-MM-DDT00:00:00
          return t('xLabel', {
            date: new Date(localdate),
          });
        },
        style: {
          color: 'var(--beam-color-subtle)',
          fontSize: '12px',
          fontFamily: 'Source Sans Pro',
          fontWeight: 400,
          lineHeight: '16px',
        },
      },
      lineColor: colors.gray[300],
      tickColor: colors.gray[200],
      tickWidth: 1,
    },
    plotOptions: {
      series: {
        animation: false,
        pointWidth: barWidth,
        pointPadding: 0.5,
      },
      column: { borderRadius: 0 },
    },
    legend: { enabled: false },
    responsive: {
      rules: [{ condition: { maxWidth: 800 } }],
    },
    series: [
      {
        name: `<span style="font-size:14px;font-weight: 600;color:${
          colors.gray[600]
        }">${t('xLegend')}</span>`,
        useHtml: true,
        data,
        states: { hover: { color: 'var(--beam-color-gray_500)' } },
      },
    ],
  };
};
