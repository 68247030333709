import { useSelector } from 'react-redux';
import { Badge, Surface, Txt } from '@vst/beam';
import { Trans, useTranslation } from 'react-i18next';

import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';

import styles from './styles.module.scss';

export const TotalDataUsed = () => {
  const { t } = useTranslation('Usage');

  const {
    data: {
      usage: { standardDataUsed, monthlyDataUsed, monthlyDataUsedRatio },
    },
  } = useSelector(selectPlanInfo);

  const totalDataUsed = standardDataUsed + monthlyDataUsed;
  const showBadge = monthlyDataUsedRatio === 1;

  return (
    <div data-cy="total-data-used" className={styles['heading']}>
      {showBadge && (
        <Surface
          mb="8px"
          radius="4px"
          theme={'light'}
          style={{ background: 'inherit' }}
        >
          <Badge
            size="small"
            emphasis={'medium'}
            state="infoPrimary"
            label={t('unlimitedStandardData')}
            showIcon={false}
          />
        </Surface>
      )}
      <Txt component="span" variant="subHeading3" color="inherit">
        <Trans
          ns="Usage"
          i18nKey="totalDataUsed"
          values={{ amount: totalDataUsed }}
        />
      </Txt>
    </div>
  );
};
