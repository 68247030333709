import { Txt, colors } from '@vst/beam';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Bar, barHighlightColorBuyMore } from '@mfe/shared/components';
import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';

import styles from './styles.module.scss';

export const BuyMoreHistoryUsageBar = () => {
  return (
    <div className={styles['usageBarContainer']}>
      <Labels />
      <DataUsageBar />
    </div>
  );
};

const Labels = () => {
  const { t } = useTranslation('Usage');

  const {
    data: {
      usage: { monthlyDataCap, monthlyDataUsed },
    },
  } = useSelector(selectPlanInfo);

  const totalData = t('Global:dataFormat', { amount: monthlyDataCap });

  return (
    <div className={styles['labelsContainer']}>
      <div data-cy="buy-more-data-used">
        <Txt color="inherit" component="span" variant="tinyBold">
          {t('Global:dataFormat', { amount: monthlyDataUsed })}
        </Txt>
        <Txt color="inherit" component="span" variant="tinyRegular">
          {t('buyMoreDataUsed')}
        </Txt>
      </div>
      <Txt data-cy="buy-more-total-data" color="inherit" variant="tinyBold">
        {t('totalDataAvailable', { totalData })}
      </Txt>
    </div>
  );
};

const DataUsageBar = () => {
  const {
    data: {
      usage: { buyMoreDataUsedRatio },
    },
  } = useSelector(selectPlanInfo);

  const consumptionBarRadius =
    buyMoreDataUsedRatio < 1 ? '16px 0px 0px 16px' : '16px';

  return (
    <div style={{ display: 'flex', margin: '4px 0 16px' }}>
      <Bar
        testID="usage-bar-buy-more"
        height={12}
        width="100%"
        percentage={buyMoreDataUsedRatio * 100}
        backgroundColor={colors.gray[200]}
        color={barHighlightColorBuyMore}
        backgroundBorderRadius="16px"
        highlightBorderRadius={consumptionBarRadius}
      />
    </div>
  );
};
