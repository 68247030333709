import React, { useState } from 'react';
import Styles from './UsageToggle.module.scss';
import { useStoreContext } from '../../context/store';
import { useTranslation } from 'react-i18next';
import { Txt } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHistoricalUsageInfo } from '@mfe/to-be-migrated/redux/usageHistory';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { useCanPlaceOrders } from '@mfe/shared/components';

interface ToggleProps {
  initialMode: 'daily' | 'monthly';
}

export const UsageToggle = ({ initialMode = 'monthly' }: ToggleProps) => {
  const { t } = useTranslation('Usage');
  const { setShowMonthly, setSelectedBillCycle } = useStoreContext();
  const [chartMode, setChartMode] = useState(initialMode);

  const {
    userInfo: { isPreInstall },
  } = useSelector(selectUserInfo);
  const { isDisconnected } = useCanPlaceOrders();
  const isCurrentUsageAvailable = !(isPreInstall && isDisconnected);
  const dispatch = useDispatch();

  const handleToggle = (chartType: 'daily' | 'monthly') => {
    setChartMode(chartType);
    setShowMonthly(chartType == 'monthly');
    if (chartType === 'daily') {
      isCurrentUsageAvailable && dispatch(fetchHistoricalUsageInfo());
      setSelectedBillCycle(0);
    }
  };

  return (
    <div data-cy="usage-toggle" className={Styles['usage-toggle-wrapper']}>
      <div className={`beam-surface--secondary p-8 ${Styles['usage-toggle']}`}>
        <button
          data-cy="monthly-usage"
          className={
            chartMode == 'monthly'
              ? Styles['toggleButtonClicked']
              : Styles['toggleButton']
          }
          onClick={() => handleToggle('monthly')}
        >
          <Txt variant="smallRegular">{t('toggleButton.monthly')}</Txt>
        </button>
        <button
          data-cy="daily-usage"
          className={
            chartMode == 'daily'
              ? Styles['toggleButtonClicked']
              : Styles['toggleButton']
          }
          onClick={() => handleToggle('daily')}
        >
          <Txt variant="smallRegular">{t('toggleButton.daily')}</Txt>
        </button>
      </div>
    </div>
  );
};
