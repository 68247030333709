import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Styles from '../DailyUsageChart/DailyUsageChart.module.scss';
import { Header } from '../Header';
import { ChartLoading } from '../../components/ChartLoading';
import { useTranslation } from 'react-i18next';
import { DonutChart } from '../../components/DonutChart/DonutChart';
import LoadingStyles from '../../components/Loading/Loading.module.scss';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { useSelector } from 'react-redux';
import { selectCurrentUsage } from '@mfe/to-be-migrated/redux/plan';
import { PreInstallState } from '../../components/PreInstallState';
import { DonutChartBillCycle } from '../../components/DonutChartBillCycle';
import { useCanPlaceOrders } from '@mfe/shared/components';
import { DisconnectedState } from '../../components/DisconnectedState';
import { InfoAlert } from '../../components/InfoAlert/InfoAlert';
import { CloseButton, Txt } from '@vst/beam';
import { useStoreContext } from '../../context/store';
import { ErrorApiComponent } from '../../components/Loading/ErrorApi';

export const TotalUsageChart = (): JSX.Element | null => {
  const { t } = useTranslation('UsageHistory');

  const {
    loading,
    errors,
    data: { startDate, endDate },
  } = useSelector(selectCurrentUsage);

  const showError = errors || (!loading && !(startDate && endDate));

  const { showAlertMessage, setShowAlertMessage } = useStoreContext();
  const { isDisconnected } = useCanPlaceOrders();
  const {
    userInfo: { isPreInstall },
  } = useSelector(selectUserInfo);

  useEffect(() => {
    // SectionAlert does not have the close button. So manipulate the DOM and attach <CloseButton/> as child
    const sectionAlert = document.getElementsByClassName('beam-section-alert');
    const closeButtonWrapper = document.createElement('span');

    if (showAlertMessage && sectionAlert.length) {
      sectionAlert[0].appendChild(closeButtonWrapper);
      closeButtonWrapper.style.marginLeft = 'auto';
      ReactDOM.render(
        <CloseButton size="small" onClick={() => setShowAlertMessage(false)} />,
        closeButtonWrapper
      );
    }
    return () => {
      // Clean up the rendered component when the SectionAlert unmounts
      ReactDOM.unmountComponentAtNode(closeButtonWrapper);
    };
  }, [showAlertMessage]);

  if (showError) {
    return <ErrorApiComponent chartTitle={t('chartTitle')} />;
  }

  if (isPreInstall) {
    return (
      <div
        className={Styles['UsageChartWrapper']}
        data-cy="usage-chart-wrapper"
      >
        <div className={Styles.TitleSection}>
          <Txt p="24px" color="regular" variant="heading5">
            {t('chartTitle')}
          </Txt>
        </div>
        <PreInstallState />
      </div>
    );
  }

  if (isDisconnected) {
    return (
      <div
        data-cy={'usage-chart-wrapper'}
        className={Styles['UsageChartWrapper']}
      >
        <div className={Styles.TitleSection}>
          <Txt p="24px" color="regular" variant="heading5">
            {t('chartTitle')}
          </Txt>
        </div>
        <DisconnectedState />
      </div>
    );
  }

  return (
    <div className={Styles['UsageChartWrapper']} data-cy="usage-chart-wrapper">
      <div className={Styles.TitleSection} data-cy="chart-title">
        <Txt p="24px" color="regular" variant={'heading5'}>
          {t('chartTitle')}
        </Txt>
        <div className={Styles.BillCycleDropdown}>
          {loading ? (
            <div
              className={LoadingStyles.billCycleNavigatorLoading}
              data-cy="loading-billcycle"
            />
          ) : (
            <DonutChartBillCycle />
          )}
        </div>
      </div>
      {showAlertMessage && <InfoAlert />}
      <Header />
      {loading ? (
        <ChartLoading />
      ) : (
        <div className={Styles.UsageChart}>
          <DonutChart />
        </div>
      )}
    </div>
  );
};
