import {
  AEMContentProvider,
  createAemUrl,
  AemFeature,
} from '@mfe/shared/components';
import { useSelector } from 'react-redux';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

export const ShieldAd = () => {
  const { aemBaseUrl } = useSelector(selectConfig);
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  return (
    <AEMContentProvider
      src={createAemUrl({
        baseUrl: aemBaseUrl,
        locale: userLocale,
        feature: AemFeature.MV_SHIELD_AD,
        accountType,
      })}
    />
  );
};
