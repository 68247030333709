import React from 'react';
import './Header.module.scss';
import LoadingStyles from '../../components/Loading/Loading.module.scss';
import Styles from './Header.module.scss';
import { useStoreContext } from '../../context/store';
import { useTranslation } from 'react-i18next';
import { Txt } from '@vst/beam';
import { UsageToggle } from '../../components/UsageToggle/UsageToggle';
import { useSelector } from 'react-redux';
import { selectHistoricalUsageInfo } from '@mfe/to-be-migrated/redux//usageHistory';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectPlanCharacteristics } from '@mfe/to-be-migrated/redux/plan';

export const Header = (): JSX.Element | null => {
  const { t } = useTranslation('UsageHistory');
  const { selectedBillCycle, showMonthly } = useStoreContext();

  const { loading, billCycles } = useSelector(selectHistoricalUsageInfo);
  const { showDonutChart } = useSelector(selectConfig);
  const {
    characteristics: { hasUnlimitedUsageMeter },
  } = useSelector(selectPlanCharacteristics);

  const averageUsage = billCycles[selectedBillCycle]?.averageUsage;
  const onlyShowUsageToggle =
    !hasUnlimitedUsageMeter && showDonutChart && showMonthly;

  if (onlyShowUsageToggle) {
    return (
      <div className={Styles.NavSection} data-cy="chart-header">
        <UsageToggle initialMode={'monthly'} />
      </div>
    );
  }

  return (
    <div className={Styles.NavSection} data-cy="chart-header">
      {!hasUnlimitedUsageMeter && showDonutChart && (
        <div style={{ flexGrow: 1 }}>
          <UsageToggle initialMode={'daily'} />
        </div>
      )}
      {loading ? (
        <div
          className={LoadingStyles.averageUsageLoading}
          data-cy="loading-average-usage"
        />
      ) : (
        <div className={Styles.AverageUsageWrapper} data-cy="average-usage">
          <Txt variant="labelMedium" color="subtle">
            {t('averageUsage', { averageUsage })}
          </Txt>
        </div>
      )}
    </div>
  );
};
