import React from 'react';
import { useTranslation } from 'react-i18next';
import { Txt, InlineLink, Button } from '@vst/beam';
import { useDispatch } from 'react-redux';

import { structuredEvent } from '@mfe/to-be-migrated/redux/analytics';
import { StreamOnStatus } from '@mfe/shared/schema-types';
import {
  openInNewTab,
  setStreamOnPageStatistics,
} from '@mfe/to-be-migrated/redux/utils';
import { resetStreamOn } from '@mfe/to-be-migrated/redux/streamOn';

import { useStreamOnAnalyticsContext } from '../utils';
import styles from '../styles.module.scss';

const StreamCardFooter = ({
  state,
}: {
  state: StreamOnStatus;
}): JSX.Element | null => {
  const { t } = useTranslation('StreamOn');
  const dispatch = useDispatch();
  const { displayContext, streamOnStatus } = useStreamOnAnalyticsContext();

  const goToFAQ = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const url = t('streamOnFAQsLink', { ns: 'Global' });

    dispatch(openInNewTab(url));

    dispatch(
      structuredEvent({
        category: 'Stream',
        action: 'learn_more_selected',
        params: {
          property: streamOnStatus ?? '',
          context: [displayContext],
        },
      })
    );
  };

  const refreshPage = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(resetStreamOn());
    dispatch(setStreamOnPageStatistics());
  };

  if (state === StreamOnStatus.RequestErrored) {
    return (
      <div
        id="StreamOnFooter"
        className={`${styles['streamOnFooter']} ${styles['timeout']}`}
      >
        <Button
          onClick={refreshPage}
          className={styles['streamFooterButton']}
          variant="secondary"
        >
          {t('Footer.refresh')}
        </Button>
      </div>
    );
  }

  return (
    <div id="StreamOnFooter" className={styles['streamOnFooter']}>
      <Txt variant="bodySmallBold" color="inherit">
        {t('Footer.needHelp')}
        <InlineLink id="footerLink" href="#" onClick={goToFAQ}>
          {t('Footer.streamOnHelp')}
        </InlineLink>
      </Txt>
    </div>
  );
};

export default StreamCardFooter;
