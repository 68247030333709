import styles from './Content.module.scss';

interface ContentProps {
  children: React.ReactNode;
  tabIndex?: number;
  isCentered?: boolean;
  hasVerticalScroll?: boolean;
}

const Content: React.FC<ContentProps> = (props) => {
  const { children, tabIndex = -1, isCentered, hasVerticalScroll } = props;

  return (
    <div
      data-cy="content"
      tabIndex={tabIndex}
      className={computeClassName(isCentered, hasVerticalScroll)}
    >
      {children}
    </div>
  );
};

function computeClassName(
  isCentered: boolean | undefined,
  hasVerticalScroll: boolean | undefined
) {
  const classNames = [styles['content']];

  if (isCentered) {
    classNames.push(styles['centered']);
  }

  if (hasVerticalScroll) {
    classNames.push(styles['has-vertical-scroll']);
  }

  return classNames.join(' ');
}

export default Content;
