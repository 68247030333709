import { StreamOnStatus } from '@mfe/shared/schema-types';
import { selectStreamOn } from '@mfe/to-be-migrated/redux/streamOn';

import StreamCardHeader from './header';
import StreamCardBody from './body';
import StreamCardFooter from './footer';

import {
  DisplayZone,
  inViewStructuredEvent,
} from '@mfe/to-be-migrated/redux/analytics';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { getDisplayContext, StreamOnAnalyticsContext } from './utils';
import {
  selectUtil,
  setStreamOnPageStatistics,
} from '@mfe/to-be-migrated/redux/utils';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Card } from '@mfe/shared/components';
import styles from './styles.module.scss';
import { useTrackTiming } from '@mfe/shared/util';
import StreamCardLoading from './loading';

interface Props {
  page?: DisplayZone;
}

const StreamCard = ({ page }: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [streamOnStatus, setStreamOnStatus] = useState<StreamOnStatus>(
    StreamOnStatus.RequestErrored
  );
  const {
    user: {
      auth: {
        isAuthenticated,
        tokenInfo: { type: tokenType },
      },
    },
  } = useSelector(selectUser);
  const {
    pageStats: {
      pages: {
        streamOn: { lastUpdatedTime },
      },
    },
  } = useSelector(selectUtil);
  const { loading: streamOnLoading, streamOn } = useSelector(selectStreamOn);

  useTrackTiming({
    eventName: 'StreamOnCardLoaded',
    isLoading: streamOnLoading,
  });

  useEffect(() => {
    if (!lastUpdatedTime && isAuthenticated)
      dispatch(setStreamOnPageStatistics());
  }, [dispatch, isAuthenticated, lastUpdatedTime]);

  useEffect(() => {
    setStreamOnStatus(streamOn.status);
  }, [streamOn]);

  useEffect(() => {
    if (tokenType && !streamOnLoading)
      dispatch(
        inViewStructuredEvent({
          selector: '.stream-on-card',
          category: 'Stream',
          action: 'card_displayed',
          triggerOnce: false,
          params: {
            property: streamOnStatus,
            context: [getDisplayContext(tokenType, page)],
          },
        })
      );
  }, [dispatch, tokenType, streamOnLoading, streamOnStatus]);

  if (streamOnLoading) return <StreamCardLoading />;

  return (
    <StreamOnAnalyticsContext.Provider
      value={{
        displayContext: getDisplayContext(tokenType, page),
        streamOnStatus: streamOnStatus,
      }}
    >
      <Card
        testID="stream-on-loaded"
        className={`${styles['streamOnCardStyle']} stream-on-card`}
      >
        <StreamCardHeader state={streamOnStatus} />
        <StreamCardBody state={streamOnStatus} />
        <StreamCardFooter state={streamOnStatus} />
      </Card>
    </StreamOnAnalyticsContext.Provider>
  );
};

export default StreamCard;
