import { useTranslation } from 'react-i18next';
import { IllustrativeIcon, Txt } from '@vst/beam';
import { ContactUs } from '@vst/beam-icons/illustrative-icons';

import styles from './styles.module.scss';

export const VoiceContent = () => {
  const { t } = useTranslation('Usage');
  return (
    <div className={styles['voiceOnly']} data-cy="voice-only-state">
      <IllustrativeIcon
        data-cy="voice-only-icon"
        name="Router"
        icon={ContactUs}
        color={'teal_600'}
        width={95}
        height={80}
      />
      <Txt data-cy="voice-only-text" variant="bodyLargeRegular">
        {t('voiceOnlyConnection')}
      </Txt>
    </div>
  );
};
