import * as React from 'react';

export type StoreValue = {
  selectedBillCycle: number;
  setSelectedBillCycle: React.Dispatch<React.SetStateAction<number>>;
  showMonthly: boolean;
  setShowMonthly: React.Dispatch<React.SetStateAction<boolean>>;
  showAlertMessage: boolean;
  setShowAlertMessage: React.Dispatch<React.SetStateAction<boolean>>;
};

const StoreContext = React.createContext<any>(null);

interface StoreProviderProps {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export const StoreProvider: React.FC<StoreProviderProps> = (props) => {
  const { value, children } = props;

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export const useStoreContext = () => {
  const value = React.useContext(StoreContext);

  if (!value) {
    throw new Error(
      '[useStoreContext] - make sure you provide a value to the StoreProvider'
    );
  }

  return value;
};
