import styles from './styles.module.scss';

export const LoadingContent = () => {
  return (
    <div data-cy="loading-content" className={styles['containerWhite']}>
      <div className={styles['planName']} />
      <div className={styles['changePlanLink']} />
      <div className={styles['separator']} />
      <div className={styles['dataRemaining']} />
      <div className={styles['daysLeft']} />
      <div className={styles['usageBar']} />
      <div className={styles['buyMoreHistory']} />
    </div>
  );
};
