import React from 'react';
import { Txt } from '@vst/beam';
import { ChartLoading } from '../ChartLoading';
import styles from './Loading.module.scss';

interface DataUsageLoadingProps {
  chartTitle: string;
  loadingMsg?: string;
}

export const DataUsageLoading: React.FC<DataUsageLoadingProps> = React.memo(
  ({ chartTitle, loadingMsg }) => {
    return (
      <div
        data-cy="chart-loading-wrapper"
        className={styles['chartLoadingContainer']}
      >
        <div data-cy="chart-title" className={styles['chartLoadingTitle']}>
          <Txt variant="heading5">{chartTitle}</Txt>
        </div>
        <div
          data-cy="loading-icon-wrapper"
          className={styles['chartLoadingAnimation']}
        >
          <ChartLoading loadingMsg={loadingMsg} />
        </div>
      </div>
    );
  }
);

export default DataUsageLoading;
