import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import accessibility from 'highcharts/modules/accessibility';
import { useTranslation } from 'react-i18next';
import { BadgeDot } from '@vst/beam';
import { useSelector } from 'react-redux';
import { differenceInDays } from 'date-fns';

import { useScreenResolution } from '@mfe/shared/util';
import { selectHistoricalUsageInfo } from '@mfe/to-be-migrated/redux/usageHistory';

import { useStoreContext } from '../../context/store';
import { getOptions } from '../../../utils';
import styles from './styles.module.scss';

if (typeof Highcharts === 'object') {
  accessibility(Highcharts);
}

export const BillCycleBarChart = (): JSX.Element | null => {
  const { t } = useTranslation('UsageHistory');

  const { selectedBillCycle } = useStoreContext();
  const { billCycles } = useSelector(selectHistoricalUsageInfo);
  const usage = billCycles[selectedBillCycle];
  const { isExtraSmall, isSmall } = useScreenResolution();

  const { usageData = [] } = usage;
  const series_data = usageData?.map((usgItem) => {
    const usgDateStr = usgItem.date; // Usage Date
    const localDateStr = `${usgDateStr}T00:00:00`;
    const localUsgDateObj = new Date(localDateStr);
    return {
      name: t('tooltipDate', {
        date: localUsgDateObj,
      }),
      x: localUsgDateObj.getTime(),
      y: usgItem.usage, // Usage consumed
    };
  });

  const isCurrentBillCycle = Number(selectedBillCycle) === 0;
  let SCROLL_TO_LEFT = 0.0;
  const MAX_DAYS_IN_BILL_CYCLE = 31;
  const MAX_BARS_IN_SMALLEST_SCREEN = 7;

  if (isCurrentBillCycle && series_data?.length > MAX_BARS_IN_SMALLEST_SCREEN) {
    const billStartDateString = usageData[0]?.date;
    if (billStartDateString) {
      const billStartDate = new Date(billStartDateString);
      const currentDate = new Date();
      const barsToShow = differenceInDays(currentDate, billStartDate);
      SCROLL_TO_LEFT = barsToShow / MAX_DAYS_IN_BILL_CYCLE;
    }
  }

  const scrollProps =
    isExtraSmall || isSmall
      ? {
          scrollablePlotArea: {
            minWidth: 575,
            scrollPositionX: SCROLL_TO_LEFT,
          },
        }
      : {};

  const options = getOptions(t, series_data, 10, scrollProps);

  return (
    <div data-cy="bar-chart" className={styles['nonUnleashedWrapper']}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <BadgeDot
        dotColor="gray_700"
        label={t('xLegend')}
        type="infoSecondary"
        className={styles['nonUnleashedBadge']}
      />
    </div>
  );
};
